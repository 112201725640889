import { SVGProps } from 'react'
import { SVGRProps } from './interfaces'

const DragIcon = ({
  title,
  titleId,
  style,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    fill="currentColor"
    width="9"
    height="16"
    viewBox="0 0 9 16"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <circle cx="1.50011" cy="1.50011" r="1.50011" fill="currentColor" />
    <circle cx="1.50011" cy="7.50011" r="1.50011" fill="currentColor" />
    <circle cx="1.50011" cy="13.5021" r="1.50011" fill="currentColor" />
    <circle cx="7.50011" cy="1.50011" r="1.50011" fill="currentColor" />
    <circle cx="7.50011" cy="7.50011" r="1.50011" fill="currentColor" />
    <circle cx="7.50011" cy="13.5021" r="1.50011" fill="currentColor" />
  </svg>
)

export default DragIcon
