import { PlayCircle } from 'react-feather'
import { SVGProps } from 'react'

interface SVGRProps {
  title?: string
  titleId?: string
}

const PlayCircleIcon = ({
  title,
  style,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => {
  return <PlayCircle style={style} />
}

export default PlayCircleIcon
