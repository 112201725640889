import React from 'react'
import ChevronLeftIcon from '../Icon/ChevronLeftIcon'
import ChevronRightIcon from '../Icon/ChevronRightIcon'
import InnerButton from '../InnerButton/InnerButton'
import './Arrow.less'
import { Tooltip } from '../Tooltip/Tooltip'

interface ArrowProps {
  direction: 'left' | 'right'
  isPage?: boolean
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void // Accepte un event
  t: any
}

export const Arrow: React.FC<ArrowProps> = ({
  direction,
  onClick,
  t,
  isPage = false,
}) => {
  return (
    <div onClick={onClick} className="arrow">
      <Tooltip
        title={
          isPage
            ? direction === 'left'
              ? t('processInstanceUpdate.reviewDocuments.previousPage')
              : t('processInstanceUpdate.reviewDocuments.nextPage')
            : direction === 'left'
              ? t('processInstanceUpdate.reviewDocuments.previousDocument')
              : t('processInstanceUpdate.reviewDocuments.nextDocument')
        }
      >
        <InnerButton className="arrow-button">
          {direction === 'left' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </InnerButton>
      </Tooltip>
    </div>
  )
}
