import { SVGProps } from 'react'
import { SVGRProps } from './interfaces'

const ClockIcon = ({
  title,
  titleId,
  style,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <div style={{ display: 'flex', alignItems: 'stretch' }}>
    <svg
      width="22"
      height="21"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <g clipPath="url(#clip0_6283_64685)">
        <path
          d="M8.11003 14.6668C11.7919 14.6668 14.7767 11.6821 14.7767 8.00016C14.7767 4.31826 11.7919 1.3335 8.11003 1.3335C4.42813 1.3335 1.44336 4.31826 1.44336 8.00016C1.44336 11.6821 4.42813 14.6668 8.11003 14.6668Z"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.10938 4V8L10.776 9.33333"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_6283_64685">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.109375)"
          />
        </clipPath>
      </defs>
    </svg>
  </div>
)

export default ClockIcon
