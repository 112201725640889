import { SVGProps } from 'react'
import { SVGRProps } from '../Icon/interfaces'

const God = ({
  style,
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <div style={style}>
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.2221 12.945C27.3841 13.1686 27.3341 13.4812 27.1105 13.6432C25.722 14.6491 24.5408 14.9017 23.5295 14.7377C22.543 14.5778 21.8022 14.0309 21.2858 13.6352C21.0666 13.4672 21.025 13.1534 21.193 12.9342C21.3609 12.715 21.6748 12.6735 21.894 12.8414C22.4071 13.2346 22.9732 13.6345 23.6895 13.7506C24.381 13.8628 25.2987 13.7209 26.5238 12.8334C26.7474 12.6714 27.0601 12.7214 27.2221 12.945Z"
        fill="#390166"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.8139 12.945C17.9759 13.1686 17.9259 13.4812 17.7023 13.6432C16.3138 14.6491 15.1326 14.9017 14.1213 14.7377C13.1348 14.5778 12.394 14.0309 11.8776 13.6352C11.6584 13.4672 11.6168 13.1534 11.7848 12.9342C11.9527 12.715 12.2666 12.6735 12.4858 12.8414C12.9989 13.2346 13.565 13.6345 14.2813 13.7506C14.9728 13.8628 15.8905 13.7209 17.1156 12.8334C17.3392 12.6714 17.6518 12.7214 17.8139 12.945Z"
        fill="#390166"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 7H28C28.5523 7 29 7.44772 29 8V25C29 25.2349 28.919 25.451 28.7834 25.6216C29.3659 25.9654 29.864 26.4365 30.2395 26.9962C30.7125 26.4659 31 25.7665 31 25V8C31 6.34315 29.6569 5 28 5H11C9.34315 5 8 6.34315 8 8V25C8 26.58 9.22145 27.8747 10.7716 27.9914C11.3207 27.3827 12.1156 27 13 27H13.2563C13.3822 26.644 13.5515 26.3085 13.758 26H11C10.4477 26 10 25.5523 10 25V8C10 7.44772 10.4477 7 11 7ZM15.0505 26C14.5146 26.5251 14.1468 27.2212 14.0354 28H28C28.5281 28 29.0243 27.8636 29.4553 27.624C28.8343 26.6477 27.7428 26 26.5 26C26.3682 26 26.238 26.0073 26.11 26.0215C26.063 26.0267 26.0164 26.0328 25.97 26.0399C25.9683 26.0266 25.9665 26.0133 25.9646 26H15.0505Z"
        fill="#390166"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5 32H13C11.8954 32 11 31.1046 11 30C11 28.8954 11.8954 28 13 28H14.0354C14.2781 26.3039 15.7368 25 17.5 25C18.1043 25 18.6728 25.1532 19.1689 25.4228C19.6232 24.0169 20.9429 23 22.5 23C24.277 23 25.7447 24.3243 25.97 26.0399C26.1429 26.0136 26.3198 26 26.5 26C27.8962 26 29.1015 26.8175 29.6632 28H31C32.1046 28 33 28.8954 33 30C33 31.1046 32.1046 32 31 32H17.5Z"
        fill="#390166"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 17.3369C5.9613 17.8303 5.2042 18.8203 5.03544 20.0001H4C2.89543 20.0001 2 20.8955 2 22.0001C2 23.1047 2.89543 24.0001 4 24.0001H7V17.3369Z"
        fill="#390166"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.0007 19C13.7066 19.4044 13.7068 19.4046 13.707 19.4047L13.7076 19.4051L13.7091 19.4062L13.7137 19.4095L13.7288 19.4202C13.7415 19.4291 13.7595 19.4417 13.7825 19.4574C13.8287 19.4888 13.8954 19.533 13.9812 19.5871C14.1526 19.6952 14.401 19.8429 14.7155 20.0062C15.3435 20.3324 16.241 20.7243 17.3204 20.986C19.4871 21.5112 22.3975 21.5115 25.2948 19.4044C25.5181 19.242 25.5675 18.9293 25.4051 18.7059C25.2426 18.4826 24.9299 18.4332 24.7066 18.5957C22.1039 20.4885 19.5143 20.4889 17.556 20.0141C16.5729 19.7758 15.7517 19.4176 15.1765 19.1188C14.8894 18.9697 14.6652 18.8362 14.5144 18.7411C14.439 18.6936 14.3821 18.6558 14.3451 18.6306C14.3265 18.618 14.313 18.6085 14.3045 18.6026L14.2957 18.5963L14.2943 18.5953C14.2942 18.5952 14.2942 18.5952 14.2943 18.5953L14.2943 18.5953M14.2943 18.5953L14.2945 18.5954C14.2946 18.5955 14.2948 18.5957 14.0007 19"
        fill="#390166"
      />
    </svg>
  </div>
)

export default God
