import { FC, useMemo } from 'react'

import Workflow, { WorkflowBaseProps } from './WorkflowBase/index'
import WorkflowIcon, { WorkflowIconProps } from './WorkflowIcon/index'
import WorkflowBody, { WorkflowBodyProps } from './WorkflowBody/index'
import WorkflowStats, { WorkflowStatsProps } from './WorkflowStats'
import WorkflowActions, { WorkflowActionsProps } from './WorkflowActions'
import WorkflowSelect, { WorkflowSelectProps } from './WorkflowSelect'

import { ColorSelection, FilterSelection } from '../../../constants/colors'

export interface WorkflowCardProps
  extends WorkflowBaseProps,
    WorkflowIconProps,
    WorkflowBodyProps,
    WorkflowStatsProps,
    WorkflowActionsProps,
    WorkflowSelectProps {}

export const WorkflowCard: FC<WorkflowCardProps> = ({
  className,
  style,
  defaultIcon,
  remoteIconUrl,
  name,
  description,
  stats,
  draggable,
  children,
  actions,
  select,
  color,
  tagged,
  ...rest
}) => {
  const isColor = (color: string): color is keyof typeof ColorSelection => {
    return Object.keys(ColorSelection).indexOf(color) !== -1
  }

  const hexColor = useMemo(
    () => (isColor(color) ? ColorSelection[color] : FilterSelection.color1),
    [color, ColorSelection]
  )

  const filterColor = useMemo(
    () => (isColor(color) ? FilterSelection[color] : FilterSelection.color1),
    [color, FilterSelection]
  )

  return (
    <span className="card-link">
      <Workflow color={hexColor} {...{ draggable, className, style, ...rest }}>
        <WorkflowIcon
          {...{ tagged, defaultIcon, remoteIconUrl }}
          color={hexColor}
          filter={filterColor}
        />
        <WorkflowBody {...{ name, description, children }} />
        {Boolean(actions) && (
          <WorkflowActions
            {...{ actions }}
            isDraggable={draggable}
            color={hexColor}
          />
        )}
        {Boolean(stats) && <WorkflowStats {...{ stats }} color={hexColor} />}
        {Boolean(select) && <WorkflowSelect {...{ select }} />}
      </Workflow>
    </span>
  )
}

export default WorkflowCard
