import { CSSProperties, ReactNode, FC } from 'react'
import classnames from 'classnames'

export type WorkflowBaseProps = {
  className?: string
  style?: CSSProperties
  children?: ReactNode
  onClick?: () => void
  color: string
  draggable: boolean
}

export const WorkflowBase: FC<WorkflowBaseProps> = ({
  className,
  style,
  children,
  color,
  ...rest
}) => {
  return (
    <div
      className={classnames('workflow', className)}
      style={{
        borderLeft: `5px solid ${color}`,
        ...(rest.onClick ? { cursor: 'pointer' } : {}),
        ...style,
      }}
      {...rest}
    >
      {children}
    </div>
  )
}

export default WorkflowBase
