import { SVGProps } from 'react'
import { SVGRProps } from './interfaces'
import { CornerDownLeft } from 'react-feather'

const CornerDownLeftIcon = ({
  title,
  titleId,
  style,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <div style={{ display: 'flex', alignItems: 'stretch' }}>
    <CornerDownLeft />
  </div>
)

export default CornerDownLeftIcon
