import { UploadCloud } from 'react-feather'
import { SVGProps } from 'react'
import { SVGRProps } from './interfaces'

const UploadCloudIcon = ({
  title,
  titleId,
  style,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => {
  return <UploadCloud style={style} />
}

export default UploadCloudIcon
