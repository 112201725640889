import FileIcon from '../../Icon/FileIcon'
import './NotFound.less'

interface NotFoundProps {
  notFoundIcon?: any
}

export const NotFound: React.FC<NotFoundProps> = ({ notFoundIcon }) => {
  return <div className="notfound-viewer">{notFoundIcon || <FileIcon />}</div>
}
