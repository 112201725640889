import { FC, ReactNode } from 'react'
import InnerButton, { ButtonType } from '../InnerButton/InnerButton'

interface ConfirmationModalProps {
  content?: ReactNode
  hideCancel?: boolean
  onConfirm: {
    onClick: () => void
    title: string
  }
  onCancel: {
    onClick: () => void
    title: string
  }
}

export const ConfirmationModal: FC<ConfirmationModalProps> = ({
  content,
  onCancel,
  onConfirm,
  hideCancel = false,
}) => {
  return (
    <>
      {content && <div className="confirmation-content">{content}</div>}
      <div className="confirmation-buttons">
        {!hideCancel && (
          <InnerButton type={ButtonType.secondary} onClick={onCancel.onClick}>
            {onCancel.title}
          </InnerButton>
        )}
        <InnerButton type={ButtonType.primary} onClick={onConfirm.onClick}>
          {onConfirm.title}
        </InnerButton>
      </div>
    </>
  )
}

export default ConfirmationModal
