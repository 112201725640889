import { SVGProps } from 'react'
import { SVGRProps } from './interfaces'

const TagPrice = ({
  title,
  titleId,
  style,
  styleText,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => {
  return (
    <>
      <div style={style}>
        <svg
          width="87"
          height="55"
          viewBox="0 0 87 55"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d_1079_9635)">
            <rect x="2" y="10" width="71" height="27" fill="#FFA500" />
          </g>
          <path d="M62.5 47L73 37H62.5V47Z" fill="#f2994a" />
          <defs>
            <filter
              id="filter0_d_1079_9635"
              x="0"
              y="0"
              width="87"
              height="55"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="7" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_1079_9635"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_1079_9635"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      </div>
      <div style={styleText}>{title}</div>
    </>
  )
}

export default TagPrice
