import { ArrowRight } from 'react-feather'
import { SVGProps, CSSProperties } from 'react'

interface SVGRProps {
  title?: string
  titleId?: string
  style?: CSSProperties
}

const RightArrowIcon = ({
  title,
  titleId,
  style,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => {
  return <ArrowRight style={style} />
}

export default RightArrowIcon
