import React, { CSSProperties } from 'react'
import classnames from 'classnames'

enum ButtonType {
  default = 'default',
  valid = 'valid',
  invalid = 'invalid',
  search = 'search',
}

type InputProps = {
  className?: string
  type: ButtonType
  onChange: (str: string) => void
  placeholder: string
  name: string
  isDisabled?: boolean
  isReadonly?: boolean
  suffix: React.ReactNode
  preffix: React.ReactNode
  value?: string
  style?: CSSProperties
}
export const Input: React.FC<InputProps> = ({
  onChange,
  name,
  className,
  placeholder,
  type,
  isDisabled,
  isReadonly,
  value,
  suffix,
  preffix,
  style,
  ...rest
}) => {
  const classes = classnames(
    'Input',
    type === 'default' && 'default',
    type === 'valid' && 'valid',
    type === 'invalid' && 'invalid',
    type === 'search' && 'search',
    className
  )
  return (
    <div
      className={
        type === 'default'
          ? 'input-block'
          : type === 'valid'
            ? 'input-valid'
            : type === 'search'
              ? 'input-search'
              : type === 'invalid'
                ? 'input-invalid'
                : ''
      }
    >
      <input
        style={style}
        className={classes}
        onChange={event => onChange(event.target.value)}
        value={value}
        disabled={isDisabled}
        readOnly={isReadonly}
        required
        {...rest}
      />
      <div className="placeholder">{placeholder}</div>
      <div className="icon-preffix">{preffix}</div>
      <div className="icon-suffix">{suffix}</div>
    </div>
  )
}

export default Input
