import React from 'react'
import classNames from 'classnames'
import './Loader.less'

interface LoaderProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string
  fullscreen?: boolean
  isRelative?: boolean
  noMinHeight?: boolean
  text?: string
  white?: boolean
  size?: 'sm' | 'md' | 'lg' | string // Adjust size options as needed
}

export const Loader: React.FC<LoaderProps> = ({
  className,
  fullscreen = false,
  isRelative = false,
  noMinHeight = false,
  text,
  white = false,
  size,
  ...props
}) => {
  return (
    <div
      className={classNames(
        'loader',
        'spin-lg',
        white && 'loader--white',
        noMinHeight && 'loader--nominheight',
        isRelative && 'loader--relative',
        fullscreen && 'loader--fullscreen',
        size && `loader--${size}`,
        className
      )}
      {...props}
    >
      <span className="spin-dot spin-dot-spin">
        <i className="spin-dot-item"></i>
        <i className="spin-dot-item"></i>
        <i className="spin-dot-item"></i>
        <i className="spin-dot-item"></i>
      </span>
    </div>
  )
}
