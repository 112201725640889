import ListIcon from '../Icon/ListIcon'
import GridIcon from '../Icon/GridIcon'

interface SwitchTableCardsProps {
  value?: boolean
  onChange: (val: boolean) => void
}

export const SwitchTableCards: React.FC<SwitchTableCardsProps> = ({
  value,
  onChange,
}) => {
  return (
    <div className="switch-table-cards">
      <div className="actif" onClick={() => onChange(!value)}>
        {value ? <GridIcon /> : <ListIcon />}
      </div>
    </div>
  )
}

export default SwitchTableCards
