import React, { useState } from 'react'
import './Text.less'
import { Loader } from '../../Loader/Loader'

interface TextProps {
  title?: string
  url: string
}

export const Text: React.FC<TextProps> = ({ title, url }) => {
  const [loading, setLoading] = useState(true)

  return (
    <div className="text-viewer">
      {loading && <Loader isRelative />}
      <iframe src={url} title={title} onLoad={() => setLoading(false)} />
    </div>
  )
}
