import { SVGProps } from 'react'
import { SVGRProps } from './interfaces'

const TreeIcon = ({
  title,
  titleId,
  style,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <div style={{ display: 'flex', alignItems: 'stretch' }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
    >
      <g>
        <path d="M16.007,11.756c0.053,0.024,0.109,0.036,0.166,0.036c0.05,0,0.101-0.009,0.147-0.028c0.209-0.083,2.061-0.838,2.605-1.758   c0.552-0.934,0.42-2.149,0.414-2.2c-0.024-0.212-0.191-0.371-0.426-0.354c-0.046,0.003-1.134,0.09-2.394,1.034   c-1.4,1.051-0.753,2.958-0.726,3.038C15.831,11.627,15.907,11.711,16.007,11.756z M17.001,9.126   c0.62-0.465,1.188-0.686,1.553-0.789c-0.013,0.354-0.079,0.859-0.317,1.261c-0.249,0.421-1.102,0.917-1.783,1.236   C16.382,10.357,16.386,9.588,17.001,9.126z" />
        <path d="M8.001,3.841C7.803,3.763,7.576,3.855,7.489,4.05C7.46,4.116,6.771,5.688,7.157,8.176c0.417,2.659,3.504,3.165,3.636,3.185   c0.02,0.003,0.04,0.004,0.061,0.004c0.087,0,0.173-0.028,0.243-0.083c0.087-0.066,0.143-0.165,0.154-0.273   c0.016-0.143,0.379-3.526-0.506-4.979C9.861,4.584,8.076,3.871,8.001,3.841z M10.496,10.47c-0.759-0.219-2.303-0.853-2.548-2.417   C7.706,6.497,7.93,5.333,8.09,4.765c0.522,0.269,1.455,0.837,1.972,1.683C10.595,7.322,10.575,9.353,10.496,10.47z" />
        <path d="M3.742,9.753c-0.003,0.074-0.041,1.837,1.302,4.048c0.875,1.437,2.378,1.726,3.448,1.726c0.686,0,1.193-0.119,1.245-0.131   c0.105-0.026,0.196-0.094,0.251-0.188s0.068-0.207,0.039-0.312c-0.041-0.142-1.021-3.499-2.432-4.531   c-1.405-1.024-3.388-1.002-3.461-1C3.921,9.37,3.748,9.54,3.742,9.753z M7.124,11.011c0.856,0.627,1.639,2.582,2,3.675   c-0.803,0.091-2.54,0.105-3.396-1.301c-0.849-1.397-1.091-2.601-1.159-3.201C5.169,10.233,6.292,10.405,7.124,11.011z" />
        <path d="M15.554,15.026c0.008,0.109,0.059,0.21,0.143,0.28c0.072,0.061,0.163,0.093,0.257,0.093c0.015,0,0.029,0,0.044-0.002   c0.26-0.028,2.57-0.304,3.457-1.161c0.9-0.871,1.124-2.264,1.133-2.323c0.032-0.21-0.106-0.41-0.315-0.453   c-0.051-0.01-1.285-0.25-2.99,0.42C15.416,12.616,15.548,14.928,15.554,15.026z M17.574,12.625   c0.874-0.344,1.603-0.414,2.052-0.414c0.023,0,0.047,0,0.07,0c-0.119,0.408-0.359,1.024-0.799,1.449   c-0.476,0.46-1.736,0.748-2.531,0.877C16.423,13.965,16.655,12.987,17.574,12.625z" />
        <path d="M11.084,12.042l-0.856,0.159c-0.113,0.021-0.213,0.09-0.271,0.19c-0.059,0.1-0.071,0.22-0.034,0.33   c0.775,2.309,0.843,8.275,0.306,10.735c-0.026,0.118,0.003,0.242,0.079,0.336s0.19,0.149,0.312,0.149h3.018   c0.189,0,0.353-0.132,0.392-0.318c0.355-1.697,0.271-3.641-0.252-5.778c1.233-1.852,1.56-4.323,1.448-6.05   c-0.013-0.191-0.159-0.348-0.351-0.371l-0.484-0.06c-0.219-0.022-0.411,0.124-0.444,0.337c-0.098,0.643-0.562,1.846-1.182,3.093   c-0.46-1.106-0.924-1.98-1.26-2.56C11.418,12.088,11.251,12.01,11.084,12.042z M12.684,16.158c0.157,0.015,0.305-0.07,0.38-0.208   c0.468-0.862,1.02-2.012,1.364-2.993c-0.06,1.197-0.34,3.066-1.41,4.58c-0.068,0.096-0.091,0.216-0.062,0.33   c0.497,1.953,0.614,3.726,0.349,5.275h-2.203c0.435-2.631,0.372-7.706-0.291-10.235l0.14-0.026c0.391,0.698,0.909,1.73,1.387,3.017   C12.392,16.045,12.527,16.146,12.684,16.158z" />
        <path d="M11.999,0.058c-5.914,0-10.727,4.557-10.727,10.158c0,3.399,1.782,6.557,4.768,8.447c0.066,0.042,0.141,0.062,0.214,0.062   c0.132,0,0.262-0.065,0.338-0.186c0.118-0.187,0.063-0.434-0.124-0.552c-2.752-1.743-4.395-4.648-4.395-7.771   c0-5.16,4.452-9.358,9.926-9.358c5.475,0,9.928,4.198,9.928,9.358c0,2.96-1.514,5.779-4.049,7.539   c-0.181,0.126-0.226,0.375-0.1,0.557c0.126,0.183,0.376,0.227,0.557,0.101c2.75-1.91,4.393-4.974,4.393-8.196   C22.728,4.615,17.914,0.058,11.999,0.058z" />
        <path d="M14.25,10.929c0.02,0.003,0.04,0.004,0.061,0.004c0.087,0,0.173-0.028,0.243-0.083c0.087-0.066,0.143-0.165,0.154-0.273   c0.021-0.188,0.191-1.863-0.284-2.639C13.941,7.15,12.98,6.766,12.94,6.75c-0.199-0.078-0.425,0.014-0.512,0.208   c-0.016,0.036-0.387,0.881-0.185,2.199C12.48,10.64,14.178,10.917,14.25,10.929z M13.045,7.703   c0.235,0.146,0.521,0.366,0.696,0.653c0.194,0.317,0.23,1.057,0.206,1.657c-0.357-0.144-0.825-0.433-0.912-0.979   C12.947,8.465,12.988,8.007,13.045,7.703z" />
      </g>
    </svg>
  </div>
)

export default TreeIcon
