import { CSSProperties, ReactNode, FC } from 'react'

import DeleteIcon from '../../../Icon/DeleteIcon'
import EditIcon from '../../../Icon/EditIcon'
import DownloadIcon from '../../../Icon/DownloadIcon'
import StarIcon from '../../../Icon/StarIcon'
import DragIcon from '../../../Icon/DragIcon'

export type WorkflowActionsProps = {
  color: string
  actions?: {
    displayFav: boolean
    isFav: boolean
    onFav?: () => void
    children?: ReactNode
    onDelete?: () => void
    onDownload?: () => void
    onEdit?: () => void
  }
  style?: CSSProperties
  isDraggable?: boolean
}

export const WorkflowActions: FC<WorkflowActionsProps> = ({
  style,
  actions,
  isDraggable,
  color,
}) => {
  return (
    <>
      {actions?.displayFav && (
        <div
          className="fav-icon"
          style={{
            color: actions?.isFav ? color : '#d1d1d1',
            fill: actions?.isFav ? color : 'white',
          }}
          onClick={evt => {
            evt.stopPropagation()
            typeof actions?.onFav === 'function' && actions.onFav()
          }}
        >
          <StarIcon />
        </div>
      )}
      {isDraggable && (
        <div className="drag-icon">
          <DragIcon />
        </div>
      )}
      <div className="actions" {...{ style }}>
        {actions?.children}
        {actions?.onDelete && (
          <div
            onClick={evt => {
              evt.stopPropagation()
              typeof actions?.onDelete === 'function' && actions.onDelete()
            }}
            className="icon"
          >
            <DeleteIcon />
          </div>
        )}
        {actions?.onDownload && (
          <div
            onClick={evt => {
              evt.stopPropagation()
              typeof actions?.onDownload === 'function' && actions.onDownload()
            }}
            className="icon"
          >
            <DownloadIcon />
          </div>
        )}
        {actions?.onEdit && (
          <div
            onClick={evt => {
              evt.stopPropagation()
              typeof actions?.onEdit === 'function' && actions.onEdit()
            }}
            className="icon"
          >
            <EditIcon />
          </div>
        )}
      </div>
    </>
  )
}

export default WorkflowActions
