import { HelpCircle } from 'react-feather'
import { SVGProps } from 'react'
import { SVGRProps } from './interfaces'

const HelpIcon = ({
  title,
  titleId,
  style,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => {
  return <HelpCircle strokeWidth="1" style={style} />
}

export default HelpIcon
