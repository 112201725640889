import { SVGProps } from 'react'
import { SVGRProps } from './interfaces'

const EmailIcon = ({
  title,
  titleId,
  style,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <div style={{ display: 'flex', alignItems: 'stretch' }}>
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 7.99938V12.9994C16 13.795 16.3161 14.5581 16.8787 15.1207C17.4413 15.6833 18.2044 15.9994 19 15.9994C19.7957 15.9994 20.5587 15.6833 21.1213 15.1207C21.6839 14.5581 22 13.795 22 12.9994V11.9994C21.9999 9.74241 21.2362 7.55186 19.8333 5.78391C18.4303 4.01596 16.4706 2.7746 14.2726 2.26168C12.0747 1.74875 9.76794 1.99442 7.72736 2.95875C5.68677 3.92307 4.03241 5.54933 3.03327 7.5731C2.03413 9.59687 1.74898 11.8991 2.22418 14.1055C2.69938 16.3119 3.90699 18.2926 5.65064 19.7256C7.39429 21.1587 9.57144 21.9597 11.8281 21.9985C14.0847 22.0373 16.2881 21.3116 18.08 19.9394"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
)
export default EmailIcon
