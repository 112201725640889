import { Flag } from 'react-feather'
import { SVGProps } from 'react'

interface SVGRProps {
  title?: string
  titleId?: string
}

const Flag2Icon = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => {
  return (
    <div>
      <Flag />
    </div>
  )
}

export default Flag2Icon
