import { CSSProperties, FC, MouseEvent, ReactNode } from 'react'

import InnerButton, { ButtonType } from '../InnerButton/InnerButton'
import PlusIcon from '../Icon/PlusIcon'

import './index.less'
import classNames from 'classnames'
import Happy from '../Icon/Happy'

interface Properties {
  onButtonClick: (e: MouseEvent<HTMLButtonElement>) => void
  className?: string
  card?: boolean
  stylePageCard?: CSSProperties
  showIcon?: boolean
  stylePageLogo?: CSSProperties
  Component?: any
  styleTitle?: CSSProperties
  title?: string
  children?: ReactNode
  buttonText?: string
  hideButtonIcon?: boolean
}

export const Empty: FC<Properties> = ({
  className,
  card,
  stylePageCard,
  showIcon = true,
  stylePageLogo,
  styleTitle,
  title,
  children,
  Component,
  buttonText,
  hideButtonIcon,
  onButtonClick,
}) => {
  return (
    <div className={classNames('empty', className, card && 'empty--card')}>
      <div style={stylePageCard} className="empty-card">
        {showIcon && <Happy className="empty-logo" style={stylePageLogo} />}
        {title && <h3 style={styleTitle}>{title}</h3>}

        {children ??
          (buttonText &&
            (() => {
              const children = (
                <InnerButton
                  type={ButtonType.primary}
                  {...(!Component && {
                    icon: !hideButtonIcon && <PlusIcon />,
                  })}
                  onClick={onButtonClick}
                >
                  {buttonText}
                </InnerButton>
              )

              return (
                <div className="empty-btn">
                  {Component ? <Component {...{ children }} /> : children}
                </div>
              )
            })())}
      </div>
    </div>
  )
}
