import { useEffect, RefObject } from 'react'

const useOnScroll = <T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: (event: Event) => void
) => {
  useEffect(() => {
    const listener = (event: Event) => {
      handler(event) // Call the handler only if the click is outside of the element passed.
    }
    const root = document.querySelector('.alf-layout-root')
    const fullPage = document.querySelector('.full-page-content')

    root?.addEventListener('scroll', listener)
    fullPage?.addEventListener('scroll', listener)

    return () => {
      root?.removeEventListener('scroll', listener)
      fullPage?.removeEventListener('scroll', listener)
    }
  }, [ref, handler]) // Reload only if ref or handler changes
}

export default useOnScroll
