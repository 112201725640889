import { Share2 } from 'react-feather'
import { SVGProps } from 'react'

interface SVGRProps {
  title?: string
  titleId?: string
}

const ShareIcon = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => {
  return <Share2 strokeWidth="1" />
}

export default ShareIcon
