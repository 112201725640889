import {
  FC,
  useRef,
  ReactElement,
  cloneElement,
  ChangeEvent,
  useState,
  KeyboardEvent,
} from 'react'

import SearchIcon from '../Icon/SearchIcon'
import CancelIcon from '../Icon/CancelIcon'
import classNames from 'classnames'
import useOnClickOutside from '../../hooks/useOnClickOutside'

interface SearchInputProps {
  onChange?: (value: number | string) => void
  onSearch?: (value: number | string) => void
  children?: ReactElement<any>
  placeholder: string
  value?: string
  disabled?: boolean
  compact?: boolean
  readOnly?: boolean
  showCalendar: boolean
}

export const SearchInput: FC<SearchInputProps> = ({
  onChange,
  onSearch,
  value = '',
  placeholder,
  disabled,
  readOnly,
  compact,
  children,
}) => {
  const [valueState, setValueState] = useState(value)
  const [visible, setVisible] = useState(false)
  const mainRef = useRef<HTMLDivElement>(null)
  const ref = useRef<HTMLInputElement>(null as unknown as HTMLInputElement)

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValueState(e.target.value)
    typeof onChange === 'function' && onChange(e.target.value)
  }

  const handleClear = () => {
    setValueState('')
    ref.current.focus()
    typeof onChange === 'function' && onChange('')
  }

  const handleSearch = () => {
    if (compact) {
      if (!visible) {
        setVisible(true)
        ref.current.focus()
        return
      } else if (!valueState || !valueState.length) {
        setVisible(false)
        return
      }
    }
    typeof onSearch === 'function' && onSearch(valueState)
  }

  const handleKeyDown = (evt: KeyboardEvent<HTMLInputElement>) => {
    if (evt.key === 'Enter') {
      handleSearch()
    }
  }

  const handleClickOutside = (evt: Event) => {
    const target = evt.target as Element
    const classname = 'extra-filter-overlay'
    if (
      target &&
      (target.classList.contains(classname) || target.closest(`.${classname}`))
    ) {
      return
    }
    setVisible(false)
  }

  useOnClickOutside(mainRef, handleClickOutside)

  const onBlur = (evt: React.FocusEvent<HTMLInputElement>) => {
    evt.preventDefault()
    const target = evt.relatedTarget
    const classnames = ['alf-search-input__search', 'alf-search-input__btns']
    if (
      target &&
      classnames.some(
        classname =>
          target.classList.contains(classname) ||
          target.closest(`.${classname}`)
      )
    ) {
      return
    }
    if (compact && visible && (!valueState || !valueState.length)) {
      setVisible(false)
    }
    evt.stopPropagation()
  }

  return (
    <div
      ref={mainRef}
      className={classNames(
        'alf-search-input',
        disabled && 'alf-search-input--disabled',
        compact && 'alf-search-input--compact',
        visible && 'alf-search-input--visible'
      )}
    >
      <input
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        onBlur={onBlur}
        value={valueState}
        disabled={disabled}
        readOnly={readOnly}
        placeholder={placeholder}
        ref={ref}
      />
      <div className="alf-search-input__btns">
        {valueState && (
          <button
            title="clear"
            onClick={handleClear}
            type="button"
            className="alf-search-input__clear"
          >
            <CancelIcon />
          </button>
        )}
        {children}
      </div>
      <button
        title="search"
        onClick={handleSearch}
        type="button"
        className="alf-search-input__search"
      >
        <SearchIcon />
      </button>
    </div>
  )
}
