import { SVGProps } from 'react'
import { SVGRProps } from './interfaces'

const Settings = ({
  title,
  titleId,
  style,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <div style={{ display: 'flex', alignItems: 'stretch' }}>
    <svg
      width="16"
      height="16"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <line x1="17" y1="12.5" x2="22" y2="12.5" stroke="currentColor" />
      <line x1="2" y1="12.5" x2="14" y2="12.5" stroke="currentColor" />
      <circle
        cx="15.5"
        cy="12.5"
        r="2"
        transform="rotate(-90 15.5 12.5)"
        stroke="currentColor"
      />
      <line x1="10" y1="5.5" x2="22" y2="5.5" stroke="currentColor" />
      <line x1="2" y1="5.5" x2="7" y2="5.5" stroke="currentColor" />
      <circle
        cx="8.5"
        cy="5.5"
        r="2"
        transform="rotate(-90 8.5 5.5)"
        stroke="currentColor"
      />
      <line x1="10" y1="19.5" x2="22" y2="19.5" stroke="currentColor" />
      <line x1="2" y1="19.5" x2="7" y2="19.5" stroke="currentColor" />
      <circle
        cx="8.5"
        cy="19.5"
        r="2"
        transform="rotate(-90 8.5 19.5)"
        stroke="currentColor"
      />
    </svg>
  </div>
)

export default Settings
