import classNames from 'classnames'
import { useMemo, useRef, useEffect, useState } from 'react'

interface ButtonToggleProps {
  value: string | boolean | number // The current value
  disabled?: boolean // If the button toggle is disabled
  checkedValue?: string | boolean | number // The value that represents checked
  uncheckedValue?: string | boolean | number // The value that represents unchecked
  checkedText: string // Text to show when checked
  uncheckedText: string // Text to show when unchecked
  onChange?: (newValue: string | boolean | number) => void // Change handler
}

export const ButtonToggle: React.FC<ButtonToggleProps> = ({
  value,
  disabled = false,
  checkedValue,
  uncheckedValue,
  checkedText,
  uncheckedText,
  onChange,
}) => {
  const [checkedWidth, setCheckedWidth] = useState<number>(0)
  const [uncheckedWidth, setUncheckedWidth] = useState<number>(0)
  const checkedRef = useRef<HTMLSpanElement | null>(null)
  const uncheckedRef = useRef<HTMLSpanElement | null>(null)

  const booleanValue = useMemo(() => {
    return checkedValue && uncheckedValue ? value === checkedValue : !!value
  }, [checkedValue, uncheckedValue, value])

  const onClick = () => {
    if (disabled) return

    const newValue =
      checkedValue && uncheckedValue
        ? booleanValue
          ? uncheckedValue
          : checkedValue
        : !booleanValue

    onChange?.(newValue)
  }

  // Calculate the widths of checked and unchecked texts
  useEffect(() => {
    setCheckedWidth(checkedRef.current?.offsetWidth || 0)
    setUncheckedWidth(uncheckedRef.current?.offsetWidth || 0)
  }, [checkedText, uncheckedText])

  // Calculate the container's width based on the larger text
  const containerWidth = Math.max(checkedWidth, uncheckedWidth)

  return (
    <div
      className={classNames('button-toggle', {
        'button-toggle--disabled': disabled,
      })}
      onClick={onClick}
      style={{ width: containerWidth * 2 }} // Set the toggle width based on the largest text
    >
      <div
        className={classNames('button-toggle-ball', {
          'button-toggle-ball--checked': booleanValue,
        })}
        style={{
          width: containerWidth, // Ball width matches the largest text width
          transform: booleanValue
            ? `translateX(${containerWidth}px)`
            : 'translateX(0)', // Ball shifts based on state
        }}
      ></div>
      <div
        className="button-toggle-part-container"
        style={{ width: containerWidth }}
      >
        <span
          ref={uncheckedRef}
          className={classNames('button-toggle-part', {
            'button-toggle-part--checked': !booleanValue,
          })}
        >
          {uncheckedText}
        </span>
      </div>
      <div
        className="button-toggle-part-container"
        style={{ width: containerWidth }}
      >
        <span
          ref={checkedRef}
          className={classNames('button-toggle-part', {
            'button-toggle-part--checked': booleanValue,
          })}
        >
          {checkedText}
        </span>
      </div>
    </div>
  )
}

export default ButtonToggle
