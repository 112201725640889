import { useState, useRef, FocusEvent, useEffect } from 'react'
import PlusIcon from '../Icon/PlusIcon'
import EnterIcon from '../Icon/EnterIcon'

interface PlaceholderInputProp {
  onAddClick: () => void
  onKeyDown: (evt: React.KeyboardEvent<HTMLInputElement>) => void
  inputValue: string
  placeholder: string
  onBlur: (evt: React.ChangeEvent<HTMLInputElement>) => void
  onChange: (evt: React.ChangeEvent<HTMLInputElement>) => void
}

export const PlaceholderInput = ({
  inputValue,
  onAddClick,
  onKeyDown,
  onBlur,
  onChange,
  placeholder,
}: PlaceholderInputProp) => {
  const [isAdding, setIsAdding] = useState(false)
  const span = useRef<HTMLSpanElement>(null)
  const [width, setWidth] = useState(0)

  const onInputBlur = (e: FocusEvent<HTMLDivElement>) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setIsAdding(false)
    }
  }

  useEffect(() => {
    setWidth(
      typeof span?.current?.offsetWidth === 'number'
        ? span?.current?.offsetWidth
        : 0
    )
  }, [inputValue])

  return (
    <>
      {isAdding ? (
        <div className="email-input-field" tabIndex={0} onBlur={onInputBlur}>
          <span className="email-input-span" ref={span}>
            {inputValue}
          </span>
          <input
            {...{ onKeyDown, onChange, onBlur }}
            title="input"
            autoFocus
            value={inputValue}
            style={{ width }}
          />
          <div className="email-input-enter" onClick={onAddClick}>
            <EnterIcon />
          </div>
        </div>
      ) : (
        <div
          className="email-input-placeholder"
          onClick={evt => {
            evt.stopPropagation()
            setIsAdding(true)
            evt.preventDefault()
          }}
        >
          <div className="email-input-add">
            <PlusIcon />
          </div>
          {placeholder}
        </div>
      )}
    </>
  )
}
