import { Loader } from '../../Loader/Loader'

// Importing styles as CSS modules or using global CSS
import 'react-pdf/dist/cjs/Page/AnnotationLayer.css'
import 'react-pdf/dist/cjs/Page/TextLayer.css'

import { Page, Document } from 'react-pdf'

// Options for loading the PDF
const options = { withCredentials: true }

// A loading spinner or component while PDF is loading
const loading = <Loader isRelative />

// Typing the props for the PdfContent component
interface PdfContentProps {
  file: string | ArrayBuffer // The file can be a URL, binary array, or ArrayBuffer
  onLoadSuccess: (data: { numPages: number }) => void // Callback when PDF is loaded
  [key: string]: any // Allow additional props for flexibility
}

// PdfContent component
export const PdfContent: React.FC<PdfContentProps> = ({
  file,
  onLoadSuccess,
  ...properties
}) => (
  <Document
    className="pdf-viewer-document"
    {...{ file, onLoadSuccess, options, loading }}
    error={<></>}
  >
    <Page className="pdf-viewer-page" {...{ ...properties, loading }} />
  </Document>
)
