import { FC, ReactNode, useRef } from 'react'
import classNames from 'classnames'
import { Draggable } from 'react-beautiful-dnd'
import ChevronDownIcon from '../Icon/ChevronDownIcon'
import DragIcon from '../Icon/DragIcon'

interface CollapseItemProps {
  icon?: ReactNode
  title: string
  children?: ReactNode
  collapsed?: boolean
  draggable?: boolean
  hideBorder?: boolean
  index: number
  onChange?: (val?: boolean) => void
}

export const CollapseItem: FC<CollapseItemProps> = ({
  icon,
  title,
  children,
  draggable,
  collapsed = false,
  hideBorder = false,
  index,
  onChange,
}) => {
  const ref = useRef<HTMLDivElement>(null)

  const onToggle = () => {
    typeof onChange === 'function' && onChange(!collapsed)
  }

  return (
    <Draggable
      key={index}
      draggableId={'row-' + index}
      isDragDisabled={!draggable}
      {...{ index }}
    >
      {(provided, snapshot) => (
        <div
          className={classNames(
            'collapse-item',
            collapsed && 'collapse-item--opened',
            hideBorder && 'collapse-item--hideborder',
            'item',
            snapshot.isDragging && 'dragging'
          )}
          style={provided.draggableProps.style}
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <div className="collapse-item-header" onClick={onToggle}>
            <div className="collapse-item-title">
              {draggable && (
                <div
                  {...provided.dragHandleProps}
                  className="collapse-item-drag"
                >
                  <DragIcon />
                </div>
              )}
              {icon && <div className="collapse-item-icon">{icon}</div>}
              {title}
            </div>
            <div className="collapse-item-chevron">
              <ChevronDownIcon />
            </div>
          </div>
          <div className="collapse-item-body">
            <div ref={ref} className="collapse-item-wrapper">
              {children}
            </div>
          </div>
        </div>
      )}
    </Draggable>
  )
}
