import { SVGProps } from 'react'

interface SVGRProps {
  title?: string
  titleId?: string
}

const FlagIcon = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line x1="4.5" y1="2" x2="4.5" y2="22" stroke="currentColor" />
      <path
        d="M17.7317 9.24651L20.142 13.5H4.5V4.5H20.142L17.7317 8.75349L17.592 9L17.7317 9.24651Z"
        stroke="currentColor"
      />
    </svg>
  )
}

export default FlagIcon
