import classNames from 'classnames'
import { isNil } from 'lodash'
import React, { useMemo } from 'react'

export interface CheckboxToggleProps {
  checked?: boolean
  defaultChecked?: boolean
  checkedValue?: any
  uncheckedValue?: any
  checkedText?: string
  /**
   *  @deprecated ButtonToggle should be used to render checked/unchecked values
   */
  uncheckedText?: string
  /**
   *  @deprecated CheckboxToggle are now uniformise with text on rigth
   */
  checkedTextOnLeft?: boolean
  disabled?: boolean
  onChange?: (value: boolean) => void
  variant?: 'default' | 'vertical'
  align?: 'center' | 'left' | 'right'
  invert?: boolean
}

export const CheckboxToggle: React.FC<CheckboxToggleProps> = ({
  checked,
  defaultChecked,
  checkedValue,
  uncheckedValue,
  disabled,
  checkedText,
  uncheckedText,
  checkedTextOnLeft,
  onChange,
  align = 'center',
  variant = 'default',
  ...rest
}) => {
  const checkboxValue = checked ? uncheckedText : checkedText

  const booleanValue = useMemo(
    () =>
      !isNil(checkedValue) && !isNil(uncheckedValue)
        ? checked === checkedValue
        : !!checked,
    [checkedValue, uncheckedValue, checked]
  )

  // Handle change function
  const handleChange = () => {
    const newValue =
      !isNil(checkedValue) && !isNil(uncheckedValue)
        ? booleanValue
          ? uncheckedValue
          : checkedValue
        : !booleanValue
    // Call the onChange prop with the inverted value if invert is true, otherwise use the normal value
    onChange?.(newValue)
  }

  return (
    <div className={classNames('toggle-wrap', variant, align)}>
      {uncheckedText && (
        <span className={classNames('toggle-text', checked && 'checked')}>
          {uncheckedText}
        </span>
      )}
      {checkedText && checkedTextOnLeft && (
        <span className={checked ? 'toggle-text checked' : 'toggle-text'}>
          {checkedText}
        </span>
      )}
      <label className="toggle">
        <input
          value={checkboxValue}
          type="checkbox"
          onChange={handleChange}
          checked={booleanValue}
          disabled={disabled}
          defaultChecked={defaultChecked}
          data-testid="checkbox-toggle-test"
          {...rest}
        />
        <span className="slider round"></span>
      </label>
      {checkedText && !checkedTextOnLeft && (
        <span className={checked ? 'toggle-text checked' : 'toggle-text'}>
          {checkedText}
        </span>
      )}
    </div>
  )
}

export default CheckboxToggle
