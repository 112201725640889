import React, {
  ChangeEvent,
  useEffect,
  useMemo,
  useRef,
  CSSProperties,
  useState,
} from 'react'
import classnames from 'classnames'
import ValidationError from '../Errors/Validation'

import Sad from '../Icon/NotValidIcon'
import Happy from '../Icon/ValidIcon'
import useAutosizeTextArea from './useAutosizeTextArea'

enum TextareaType {
  default = 'default',
  valid = 'valid',
  invalid = 'invalid',
}

interface TextareaFieldProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  variant?: TextareaType
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void
  value: string
  error?: string
  withResize?: boolean
  withCounter?: boolean
  autoResize?: boolean
  minCounterValue?: number
  maxCounterValue?: number
  counterLabel?: string
  styleTextArea?: CSSProperties
}

export const TextareaField: React.FC<TextareaFieldProps> = ({
  onChange,
  name,
  className,
  placeholder,
  variant = 'default',
  readOnly,
  value = '',
  required,
  error,
  withResize = false,
  withCounter,
  minCounterValue,
  maxCounterValue,
  counterLabel,
  styleTextArea,
  autoResize = false,
  ...rest
}) => {
  const props: any = rest
  const textAreaRef = useRef<HTMLTextAreaElement>(null)
  const [textAreaFocus, setTextAreaFocus] = useState(false)

  useEffect(() => {
    if (!textAreaRef.current || !withResize) return
    textAreaRef.current.style.height = '0px'
    textAreaRef.current.style.height = textAreaRef.current.scrollHeight + 'px'
  }, [value, textAreaRef])

  const finalRequired = useMemo(
    () => required || (typeof props === 'object' && props['aria-required']),
    [required, props]
  )

  const textareaWrapClassName = (variant: string) => {
    switch (variant) {
      case 'default':
        return 'textarea-block'
      case 'valid':
        return 'textarea-valid'
      case 'invalid':
        return 'textarea-invalid'
    }
  }

  const isValidValueLength = useMemo(() => {
    if (!withCounter) return true
    const minValidation = minCounterValue
      ? value?.length > minCounterValue
      : !!value?.length
    const maxValidation = maxCounterValue
      ? value?.length < maxCounterValue
      : !!value?.length

    return minValidation && maxValidation
  }, [value, withCounter, minCounterValue, maxCounterValue])

  autoResize && useAutosizeTextArea(textAreaRef.current, value)

  return (
    <div className="textarea--wrap">
      <div
        className={classnames(
          'textarea-default',
          textareaWrapClassName(variant)
        )}
        style={styleTextArea}
      >
        <div
          className={classnames(
            'textarea-wrapper',
            withCounter && 'textarea-wrapper--withcounter',
            variant,
            className,
            textAreaFocus && 'textarea-wrapper--focus',
            value && 'textarea-wrapper--hasvalue'
          )}
        >
          <textarea
            ref={textAreaRef}
            onChange={onChange}
            onFocus={() => setTextAreaFocus(true)}
            onBlur={() => setTextAreaFocus(false)}
            value={value}
            data-testid="textarea-test"
            {...rest}
          />
        </div>

        <label className="placeholder">
          {finalRequired && <span className="required">*</span>}
          {placeholder}
        </label>
        {withCounter && (
          <div
            className={classnames(
              'textarea-count',
              isValidValueLength && 'textarea-count-valid'
            )}
          >
            {isValidValueLength ? <Happy /> : <Sad />}
            {value?.length || 0} {!!counterLabel && counterLabel}
          </div>
        )}
      </div>
      {error && <ValidationError error={error} />}
    </div>
  )
}
