import { SVGProps } from 'react'
import { SVGRProps } from '../Icon/interfaces'

const FeatureFreemium = ({
  className,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="141"
    height="160"
    viewBox="0 0 141 160"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_9343_55542)">
      <path
        d="M112.329 68.3936H112.007C111.681 68.3087 111.402 68.0997 111.229 67.8116C111.056 67.5234 111.004 67.1792 111.083 66.8529C116.508 44.7219 139.211 39.059 139.412 38.9989C139.574 38.9595 139.742 38.9524 139.907 38.9778C140.072 39.0032 140.231 39.0608 140.374 39.1472C140.516 39.2336 140.641 39.3471 140.739 39.4813C140.838 39.6156 140.909 39.7678 140.949 39.9294C140.988 40.0911 140.995 40.2588 140.97 40.4232C140.944 40.5876 140.887 40.7454 140.8 40.8876C140.713 41.0297 140.599 41.1534 140.464 41.2516C140.33 41.3499 140.177 41.4208 140.014 41.4602C139.793 41.4602 118.617 46.8429 113.554 67.4732C113.477 67.7375 113.315 67.9698 113.095 68.1356C112.874 68.3014 112.605 68.3918 112.329 68.3936Z"
        fill="#390864"
      />
      <path
        d="M113.054 32.7969H112.853C112.688 32.7725 112.529 32.7157 112.386 32.63C112.243 32.5442 112.119 32.4312 112.02 32.2973C111.921 32.1633 111.849 32.0113 111.81 31.8498C111.77 31.6883 111.763 31.5205 111.788 31.3562C114.039 17.3492 127.279 12.9469 127.861 12.7669C128.174 12.6955 128.501 12.7427 128.78 12.8992C129.059 13.0557 129.27 13.3103 129.37 13.6131C129.471 13.9159 129.454 14.2452 129.324 14.5365C129.193 14.8278 128.958 15.0602 128.665 15.1881C128.524 15.1881 116.309 19.3902 114.32 31.7564C114.271 32.0531 114.116 32.3222 113.883 32.5136C113.651 32.705 113.356 32.8057 113.054 32.7969Z"
        fill="currentColor"
      />
      <path
        d="M132.06 54.9883L132.08 59.1504L135.516 61.4915L131.538 62.7922L130.372 66.7542L127.901 63.4125L123.742 63.5125L126.193 60.1509L124.807 56.2489L128.765 57.5296L132.06 54.9883Z"
        fill="#FFBC39"
      />
      <path
        d="M36.7877 49.7656L39.9621 52.447L43.9804 51.3464L42.4133 55.1883L44.7037 58.6701L40.5448 58.3699L37.9329 61.6115L36.9485 57.5695L33.0508 56.1088L36.5868 53.9077L36.7877 49.7656Z"
        fill="#FFBC39"
      />
      <path
        d="M36.9275 43.7027C36.6396 43.7018 36.3604 43.6046 36.1346 43.4269C35.9087 43.2491 35.7493 43.001 35.6818 42.7223C35.6818 42.5022 30.5988 21.3315 9.96512 15.9688C9.80418 15.9268 9.65314 15.8536 9.5206 15.7534C9.38806 15.6532 9.27665 15.528 9.1927 15.3849C9.10874 15.2419 9.05385 15.0837 9.03126 14.9196C9.00867 14.7554 9.01878 14.5884 9.061 14.4281C9.10321 14.2678 9.17672 14.1174 9.27731 13.9854C9.3779 13.8534 9.50362 13.7423 9.64728 13.6587C9.79093 13.5751 9.9497 13.5205 10.1145 13.498C10.2794 13.4755 10.4471 13.4856 10.6081 13.5276C32.7084 19.2705 38.1129 41.9418 38.1732 42.1619C38.2401 42.4903 38.1771 42.8319 37.9975 43.1152C37.8178 43.3985 37.5353 43.6018 37.2088 43.6827L36.9275 43.7027Z"
        fill="#390864"
      />
      <path
        d="M3.03377 21.2299L8.33784 23.8712L13.5214 21.0098L12.6374 26.8527L16.9771 30.8747L11.1305 31.8552L8.61912 37.1979L5.88672 31.9552L0 31.2349L4.15888 27.0328L3.03377 21.2299Z"
        fill="#FFBC39"
      />
      <path
        d="M99.4517 0.22011L104.756 2.86143L109.939 0L109.055 5.84292L113.395 9.86493L107.548 10.8454L105.037 16.1881L102.285 10.9455L96.418 10.2251L100.577 6.02301L99.4517 0.22011Z"
        fill="#FFBC39"
      />
      <path
        d="M23.6074 47.6843C24.8834 47.6843 25.9178 46.6541 25.9178 45.3832C25.9178 44.1123 24.8834 43.082 23.6074 43.082C22.3313 43.082 21.2969 44.1123 21.2969 45.3832C21.2969 46.6541 22.3313 47.6843 23.6074 47.6843Z"
        fill="#95D0DD"
      />
      <path
        d="M116.189 44.5623C117.454 44.5623 118.479 43.541 118.479 42.2811C118.479 41.0213 117.454 40 116.189 40C114.924 40 113.898 41.0213 113.898 42.2811C113.898 43.541 114.924 44.5623 116.189 44.5623Z"
        fill="#390864"
      />
      <path
        d="M41.5912 35.5974C42.8561 35.5974 43.8816 34.5761 43.8816 33.3163C43.8816 32.0565 42.8561 31.0352 41.5912 31.0352C40.3262 31.0352 39.3008 32.0565 39.3008 33.3163C39.3008 34.5761 40.3262 35.5974 41.5912 35.5974Z"
        fill="#390864"
      />
      <path
        d="M20.8607 69.1111C22.1257 69.1111 23.1511 68.0898 23.1511 66.83C23.1511 65.5701 22.1257 64.5488 20.8607 64.5488C19.5958 64.5488 18.5703 65.5701 18.5703 66.83C18.5703 68.0898 19.5958 69.1111 20.8607 69.1111Z"
        fill="#390864"
      />
      <path
        d="M126.915 78.0584C128.18 78.0584 129.206 77.0371 129.206 75.7772C129.206 74.5174 128.18 73.4961 126.915 73.4961C125.65 73.4961 124.625 74.5174 124.625 75.7772C124.625 77.0371 125.65 78.0584 126.915 78.0584Z"
        fill="#95D0DD"
      />
      <path
        d="M126.095 27.2224C127.36 27.2224 128.385 26.2011 128.385 24.9413C128.385 23.6815 127.36 22.6602 126.095 22.6602C124.83 22.6602 123.805 23.6815 123.805 24.9413C123.805 26.2011 124.83 27.2224 126.095 27.2224Z"
        fill="#95D0DD"
      />
      <path
        d="M91.017 13.49C92.2819 13.49 93.3074 12.4687 93.3074 11.2089C93.3074 9.94903 92.2819 8.92773 91.017 8.92773C89.752 8.92773 88.7266 9.94903 88.7266 11.2089C88.7266 12.4687 89.752 13.49 91.017 13.49Z"
        fill="#95D0DD"
      />
      <path
        d="M60.2955 160C59.8216 160.006 59.3626 159.835 59.0096 159.519C58.8162 159.336 58.6615 159.116 58.5546 158.872C58.4476 158.628 58.3905 158.365 58.3868 158.099V92.7061C58.3705 92.4573 58.4041 92.2079 58.4854 91.9722C58.5667 91.7365 58.6943 91.5192 58.8606 91.3331C59.027 91.1469 59.2289 90.9955 59.4545 90.8876C59.6802 90.7798 59.925 90.7178 60.175 90.7051L125.491 86.8431C126.017 86.8239 126.529 87.0108 126.918 87.3634C127.292 87.728 127.508 88.2233 127.521 88.7441V150.875C127.498 151.337 127.315 151.777 127.004 152.119C126.693 152.462 126.272 152.687 125.813 152.756L60.4965 159.98L60.2955 160ZM62.3046 94.4069V155.978L123.804 149.194V90.7051L62.3046 94.4069Z"
        fill="currentColor"
      />
      <path
        d="M60.2932 159.999C59.9481 159.998 59.609 159.909 59.3088 159.739L23.3857 138.729C23.1047 138.554 22.8714 138.313 22.7066 138.026C22.5419 137.74 22.4508 137.418 22.4414 137.088V77.3178C22.4413 77.0034 22.5203 76.694 22.6712 76.4178C22.8221 76.1417 23.0401 75.9077 23.3053 75.7371C23.5644 75.5595 23.8651 75.4514 24.1783 75.4233C24.4915 75.3953 24.8068 75.4482 25.0935 75.577L61.0366 90.8646C61.3762 91.021 61.6652 91.2686 61.8711 91.5797C62.0769 91.8908 62.1915 92.2531 62.2019 92.6255V158.098C62.1937 158.433 62.1011 158.761 61.9326 159.051C61.7641 159.341 61.5251 159.585 61.2376 159.759C60.9474 159.915 60.623 159.998 60.2932 159.999ZM26.2587 135.987L58.4046 154.797V93.8661L26.2587 80.1993V135.987Z"
        fill="currentColor"
      />
      <path
        d="M78.2349 116.599C77.5689 116.626 76.9196 116.388 76.4297 115.937C75.9399 115.487 75.6497 114.861 75.6231 114.198C75.5965 113.535 75.8355 112.888 76.2876 112.4C76.7398 111.912 77.368 111.623 78.0341 111.597L90.5107 110.676C91.1726 110.638 91.8233 110.859 92.3229 111.293C92.8226 111.727 93.1313 112.339 93.1828 112.997C93.2102 113.325 93.172 113.655 93.0703 113.968C92.9687 114.28 92.8056 114.57 92.5906 114.819C92.3757 115.069 92.1131 115.273 91.8181 115.421C91.5231 115.568 91.2017 115.656 90.8724 115.679L78.4158 116.599H78.2349Z"
        fill="currentColor"
      />
      <path
        d="M108.371 114.838C110.224 114.838 111.726 113.342 111.726 111.496C111.726 109.65 110.224 108.154 108.371 108.154C106.518 108.154 105.016 109.65 105.016 111.496C105.016 113.342 106.518 114.838 108.371 114.838Z"
        fill="currentColor"
      />
      <path
        d="M101.812 91.765C101.488 91.7598 101.18 91.6296 100.951 91.4018C100.722 91.174 100.591 90.8664 100.586 90.5443V40.8395C100.586 26.8325 91.0831 22.2302 89.536 21.5498H40.1519C43.0627 23.8326 45.3863 26.7726 46.9304 30.1268C48.4746 33.481 49.1951 37.1531 49.0323 40.8395V88.1231C49.0547 88.2979 49.0395 88.4754 48.9876 88.6439C48.9358 88.8124 48.8485 88.9678 48.7316 89.1001C48.6147 89.2323 48.4708 89.3383 48.3096 89.4109C48.1484 89.4835 47.9736 89.5209 47.7967 89.5209C47.6198 89.5209 47.4448 89.4835 47.2836 89.4109C47.1224 89.3383 46.9785 89.2323 46.8616 89.1001C46.7447 88.9678 46.6575 88.8124 46.6057 88.6439C46.5539 88.4754 46.5387 88.2979 46.5611 88.1231V40.8395C46.5611 25.9721 35.772 21.6498 35.33 21.4698C35.0597 21.3708 34.833 21.1802 34.6897 20.9314C34.5465 20.6826 34.4958 20.3914 34.5465 20.1091C34.6018 19.8299 34.7517 19.5782 34.9712 19.396C35.1906 19.2139 35.4663 19.1124 35.7519 19.1086H89.7771C89.9229 19.0784 90.0734 19.0784 90.2192 19.1086C90.3598 19.1086 103.058 24.1511 103.058 40.7594V90.4643C103.066 90.6322 103.039 90.7999 102.98 90.9575C102.922 91.115 102.831 91.259 102.715 91.3806C102.598 91.5023 102.458 91.599 102.303 91.6651C102.148 91.7311 101.981 91.7652 101.812 91.765Z"
        fill="#27AE60"
      />
      <path
        d="M41.5884 86.2638C41.2847 86.2677 40.9899 86.1626 40.7575 85.9679C40.5252 85.7732 40.3708 85.5018 40.3226 85.2032C40.3226 84.8631 34.898 52.1066 1.32567 45.6434C0.997486 45.5757 0.70881 45.383 0.521172 45.1064C0.333533 44.8298 0.261763 44.4912 0.321106 44.1627C0.352396 43.9996 0.415691 43.8442 0.507437 43.7056C0.599184 43.5669 0.717547 43.4478 0.855698 43.3548C0.993848 43.2618 1.14909 43.1969 1.31248 43.1639C1.47588 43.1309 1.64422 43.1303 1.80783 43.1622C37.1683 49.9656 42.7939 84.4629 42.8541 84.823C42.9001 85.1571 42.812 85.4957 42.6089 85.7654C42.4058 86.0351 42.104 86.2143 41.7692 86.2638H41.5884Z"
        fill="currentColor"
      />
      <path
        d="M80 78.5172L84.6429 83L95 73"
        stroke="#27AE60"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <path
        d="M92.9619 138.289C87.8201 138.264 82.7606 137.001 78.215 134.607C77.6395 134.273 77.221 133.724 77.0515 133.083C76.8819 132.441 76.9753 131.758 77.3109 131.185C77.6466 130.612 78.1972 130.195 78.8415 130.026C79.4858 129.857 80.171 129.951 80.7465 130.285C85.0535 132.49 89.8751 133.506 94.7099 133.226C103.912 132.546 108.372 128.844 108.432 128.804C108.936 128.369 109.592 128.151 110.257 128.198C110.922 128.245 111.541 128.553 111.978 129.054C112.415 129.556 112.634 130.21 112.587 130.872C112.54 131.534 112.231 132.151 111.727 132.586C111.486 132.786 106.081 137.388 95.0715 138.209C94.3884 138.269 93.6852 138.289 92.9619 138.289Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.2311 75.417L46.7713 74.0879V77.9025L32.5489 78.7387L60.6765 90.7447L117.474 87.4029L104.013 81.6801C103.692 81.402 103.468 81.0297 103.374 80.6168C103.279 80.2039 103.318 79.7717 103.485 79.3823C103.653 78.993 103.94 78.6667 104.306 78.4503C104.671 78.234 105.097 78.1387 105.52 78.1783L126.415 87.0628C126.757 87.2729 127.028 87.5804 127.193 87.9454C127.358 88.3105 127.409 88.7164 127.34 89.1107C127.27 89.505 127.084 89.8697 126.805 90.1574C126.525 90.4451 126.165 90.6427 125.772 90.7247L60.4556 94.5665L60.2948 94.5265C60.0322 94.5233 59.7729 94.469 59.5313 94.3665L23.6083 79.0788C23.2662 78.8702 22.9947 78.5643 22.8286 78.2006C22.6626 77.8369 22.6097 77.432 22.6767 77.0381C22.7437 76.6442 22.9275 76.2794 23.2046 75.9905C23.4816 75.7016 23.8391 75.5018 24.2311 75.417Z"
        fill="currentColor"
      />
      <line
        x1="54.5"
        y1="27.5"
        x2="72.5"
        y2="27.5"
        stroke="#27AE60"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <line
        x1="56.5"
        y1="34.5"
        x2="74.5"
        y2="34.5"
        stroke="#27AE60"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <line
        x1="56.5"
        y1="40.5"
        x2="87.5"
        y2="40.5"
        stroke="#27AE60"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <line
        x1="56.5"
        y1="47.5"
        x2="87.5"
        y2="47.5"
        stroke="#27AE60"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <line
        x1="56.5"
        y1="53.5"
        x2="87.5"
        y2="53.5"
        stroke="#27AE60"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <line
        x1="56.5"
        y1="60.5"
        x2="87.5"
        y2="60.5"
        stroke="#27AE60"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <line
        x1="56.5"
        y1="66.5"
        x2="69.5"
        y2="66.5"
        stroke="#27AE60"
        strokeWidth="3"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_9343_55542">
        <rect width="141" height="160" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default FeatureFreemium
