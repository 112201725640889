import { SVGProps } from 'react'
import { Award } from 'react-feather'

import { SVGRProps } from './interfaces'

const AwardIcon = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => {
  return (
    <div className={props.className}>
      <Award />
    </div>
  )
}

export default AwardIcon
