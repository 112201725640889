import { X } from 'react-feather'
import { SVGProps } from 'react'

interface SVGRProps {
  title?: string
  titleId?: string
}

const CancelIcon = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => {
  return <X />
}

export default CancelIcon
