import { SVGProps } from 'react'
import { ArrowUp } from 'react-feather'

import { SVGRProps } from './interfaces'

const ArrowUpIcon = ({
  title,
  titleId,
  style,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => {
  return (
    <div className={props.className}>
      <ArrowUp style={style} />
    </div>
  )
}

export default ArrowUpIcon
