import React, { ReactNode, ReactElement } from 'react'
import RC_Tooltip from 'rc-tooltip'
import 'rc-tooltip/assets/bootstrap.css' // Importing the default styles, or you can use your own
import './Tooltip.less'
import { isNil } from 'lodash'

export interface TooltipProps {
  children: ReactElement | string
  title?: ReactNode
  placement?:
    | 'top'
    | 'bottom'
    | 'left'
    | 'right'
    | 'topLeft'
    | 'topRight'
    | 'bottomLeft'
    | 'bottomRight'
    | 'leftTop'
    | 'leftBottom'
    | 'rightTop'
    | 'rightBottom'
  mouseEnterDelay?: number
  mouseLeaveDelay?: number
  styles?: any
}

export const Tooltip: React.FC<TooltipProps> = ({
  title,
  children = '',
  placement = 'top',
  ...props
}) => {
  return (
    <RC_Tooltip
      placement={placement}
      {...props} // Spread the rest of the props into the rc-tooltip
      overlay={title}
    >
      {isNil(children) || typeof children === 'string' ? (
        <span>{children}</span>
      ) : (
        children
      )}
    </RC_Tooltip>
  )
}
