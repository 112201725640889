import { ReactNode } from 'react'

export enum popoverPostitionEnum {
  Top,
  Bottom,
}

export interface IPopover {
  title: string
  content?: string
  placement: popoverPostitionEnum
  children?: ReactNode
  trigger?: string
}
export default popoverPostitionEnum
