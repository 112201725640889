import { SVGProps } from 'react'
import { SVGRProps } from './interfaces'

const PostItIcon = ({
  title,
  style,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <div style={{ display: 'flex', alignItems: 'stretch' }}>
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      stroke="currentColor"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M29.374 20.9029V4.94038C29.374 4.7662 29.2328 4.625 29.0586 4.625H4.93941C4.76523 4.625 4.62402 4.7662 4.62402 4.94038V29.0596C4.62402 29.2338 4.76523 29.375 4.93941 29.375H20.7653M29.374 20.9029H21.0807C20.9065 20.9029 20.7653 21.0441 20.7653 21.2183V29.375M29.374 20.9029L20.7653 29.375"
        strokeWidth="1.5"
      />
    </svg>
  </div>
)

export default PostItIcon
