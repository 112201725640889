import { SVGProps } from 'react'
import { SVGRProps } from './interfaces'

const Services = ({
  title,
  titleId,
  style,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <div style={{ display: 'flex', alignItems: 'stretch' }}>
    <svg
      width="16"
      height="16"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <rect
        x="2.5"
        y="2.5"
        width="8"
        height="8"
        rx="2.5"
        stroke="currentColor"
      />
      <rect
        x="2.5"
        y="13.5"
        width="8"
        height="8"
        rx="2.5"
        stroke="currentColor"
      />
      <rect
        x="13.5"
        y="2.5"
        width="8"
        height="8"
        rx="2.5"
        stroke="currentColor"
      />
      <rect x="17" y="14" width="1" height="7" fill="currentColor" />
      <rect
        x="21"
        y="17"
        width="1"
        height="7"
        transform="rotate(90 21 17)"
        fill="currentColor"
      />
    </svg>
  </div>
)

export default Services
