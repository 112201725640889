import { Bookmark } from 'react-feather'
import { SVGProps } from 'react'
import { SVGRProps } from './interfaces'

const BookmarkIcon = ({
  title,
  titleId,
  style,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => {
  return (
    <div className={props.className}>
      <Bookmark style={style} />
    </div>
  )
}

export default BookmarkIcon
