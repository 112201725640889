import { SVGProps } from 'react'
import { SVGRProps } from './interfaces'

const Compose = ({
  title,
  titleId,
  style,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <div style={{ display: 'flex', alignItems: 'stretch' }}>
    <svg
      width="16"
      height="16"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M13.5 6H18C19.6569 6 21 7.34315 21 9V9C21 10.6569 19.6569 12 18 12H6C4.34315 12 3 13.3431 3 15V15C3 16.6569 4.34315 18 6 18H10.5"
        stroke="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 4V3C3.34315 3 2 4.34315 2 6C2 7.65685 3.34315 9 5 9V8C3.89543 8 3 7.10457 3 6C3 4.89543 3.89543 4 5 4Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 8L11 9C12.6569 9 14 7.65685 14 6C14 4.34315 12.6569 3 11 3L11 4C12.1046 4 13 4.89543 13 6C13 7.10457 12.1046 8 11 8Z"
        fill="currentColor"
      />
      <line x1="5" y1="3.5" x2="11" y2="3.5" stroke="currentColor" />
      <line x1="5" y1="8.5" x2="11" y2="8.5" stroke="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 16V15C11.3431 15 10 16.3431 10 18C10 19.6569 11.3431 21 13 21V20C11.8954 20 11 19.1046 11 18C11 16.8954 11.8954 16 13 16Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 20L19 21C20.6569 21 22 19.6569 22 18C22 16.3431 20.6569 15 19 15L19 16C20.1046 16 21 16.8954 21 18C21 19.1046 20.1046 20 19 20Z"
        fill="currentColor"
      />
      <line x1="13" y1="15.5" x2="19" y2="15.5" stroke="currentColor" />
      <line x1="13" y1="20.5" x2="19" y2="20.5" stroke="currentColor" />
    </svg>
  </div>
)

export default Compose
