import useSWR from 'swr/immutable'

import { get } from './get'

import { revalidateOnMountOption } from './revalidateOnMountOption'
import { convert } from '../../DocumentEditor/convert'

export const useConverted = url =>
  useSWR(
    url,
    async () => convert(await get(url, 'blob')),
    revalidateOnMountOption
  ).data
