import React, { useEffect } from 'react'

import { useDocument } from './use'

import { DocumentMain } from './DocumentMain'
import { loadToolbar } from '../../DocumentEditor/loadToolbar'
import { endpoint } from '../../DocumentEditor/endpoint'

// Ensure toolbar is loaded initially
loadToolbar()

let loaded = false

// Define the props for the Document component
interface DocumentProps {
  syncfusionDocumentText?: boolean
  editing?: boolean
  reference?: React.Ref<any>
  url?: string
  [key: string]: any // Allow additional props for flexibility
}

export const Document: React.FC<DocumentProps> = ({
  syncfusionDocumentText,
  editing,
  reference,
  t,
  imageClassName,
  onNextClick,
  onPreviousClick,
  url,
  ...properties
}) => {
  useEffect(() => {
    if (editing && !loaded) {
      loadToolbar()
      loaded = true
    }
  }, [editing])

  return (
    <DocumentMain
      content={useDocument(syncfusionDocumentText, url)}
      {...{
        ...(editing && {
          enableToolbar: true,
          enableAutoFocus: true,
          restrictEditing: false,
          ref: reference,
          serviceUrl: endpoint,
        }),
        ...properties,
      }}
    />
  )
}
