import { useEffect } from 'react'
import { SquareCheckbox } from '../../SquareCheckbox/SquareCheckbox'
import { PermissionRowProps, Role } from '../PermissionTable.d'

export const PermissionRow = ({
  editable,
  feature,
  roles,
  setRoles,
}: PermissionRowProps) => {
  useEffect(() => {
    const $roleColumnsElements =
      document.querySelectorAll<HTMLElement>('.role-columns')

    const onScroll = (event: Event) => {
      const target = event.target as HTMLElement
      const scrollLeft = target.scrollLeft

      $roleColumnsElements.forEach(el => {
        if (el !== target) {
          el.scrollLeft = scrollLeft
        }
      })
    }

    $roleColumnsElements.forEach(el => {
      el.addEventListener('scroll', onScroll)
    })
  }, [])

  const handleCheckboxChange = (roleId: number, featureName: string) => {
    setRoles(
      roles.map(role =>
        role.sortOrder === roleId
          ? {
              ...role,
              features: role.features.includes(featureName)
                ? role.features.filter(
                    (feature: string) => feature !== featureName
                  )
                : [...role.features, featureName],
            }
          : role
      )
    )
  }

  return (
    <div className="permission-row">
      <div className="permission-column title">{feature.title}</div>
      <div className="role-columns">
        {roles.map((role: Role) => (
          <div className="permission-column" key={role.sortOrder}>
            <SquareCheckbox
              onChange={() =>
                editable &&
                handleCheckboxChange(role.sortOrder as number, feature.name)
              }
              checked={role.features.includes(feature.name)}
            />
          </div>
        ))}
      </div>
    </div>
  )
}
