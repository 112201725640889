import { CSSProperties, FC, ReactNode, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import XIcon from '../Icon/XIcon'

import classNames from 'classnames'
export interface ModalProps {
  isShown: boolean
  hide: () => void
  modalContent: ReactNode
  headerText: string | ReactNode
  style?: IModalStyle
  withBackgroundContent?: boolean
  className?: string
  isCloseButtonShown?: Boolean
}

interface IModalStyle {
  contentStyle?: CSSProperties
  headerStyle?: CSSProperties
  mainStyle?: CSSProperties
  mainHeaderStyle?: CSSProperties
  closeButtonStyle?: CSSProperties
}

export const Modal: FC<ModalProps> = ({
  isShown,
  hide,
  modalContent,
  headerText,
  style,
  withBackgroundContent,
  className,
  isCloseButtonShown = true,
}) => {
  const handleHide = (
    event:
      | React.MouseEvent<HTMLDivElement, MouseEvent>
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault() // Prevent default actions
    event.stopPropagation() // Prevent event bubbling
    typeof hide === 'function' && hide() // Call the hide function to close the modal
  }

  const onKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'escape' && isShown) {
      typeof hide === 'function' && hide()
    }
  }
  const [index, setIndex] = useState(1)
  useEffect(() => {
    setIndex(document.querySelectorAll('.styled-modal').length)
    document.addEventListener('keydown', onKeyDown, false)
    return () => {
      document.removeEventListener('keydown', onKeyDown, false)
    }
  }, [isShown])
  const modal = (
    <>
      <div
        className="backdrop"
        onClick={handleHide}
        style={{ zIndex: 1010 * index }}
      />

      <div
        className={classNames('styled-modal', className)}
        style={{ ...style?.mainStyle, zIndex: 1011 * index }}
      >
        <div className="header" style={style?.mainHeaderStyle}>
          <div className="header-text" style={style?.headerStyle}>
            {headerText}
          </div>
          {isCloseButtonShown && (
            <button
              className="close-button"
              onClick={handleHide}
              style={style?.closeButtonStyle}
            >
              <XIcon />
            </button>
          )}
        </div>
        <div
          className={classNames(
            'content',
            withBackgroundContent && 'background-content'
          )}
          style={style?.contentStyle}
        >
          {modalContent}
        </div>
      </div>
    </>
  )

  return isShown ? ReactDOM.createPortal(modal, document.body) : null
}

export default Modal
