import React from 'react'

import DropDown from '../../../Dropdown/Dropdown'
import DeleteIcon from '../../../Icon/DeleteIcon'

export type WorkflowSelectProps = {
  select?: {
    placeholder: string
    options?: {
      id: string
      text: string
      onDeleteOption?: () => void
    }[]
  }
}

export const WorkflowSelect: React.FC<WorkflowSelectProps> = ({ select }) => {
  const getIconText = (text: string) =>
    text
      .match(/(^[A-Z])|(\b[A-Z][a-z]?)/g)
      ?.join('')
      .toUpperCase() ?? ''

  const DropDownStyles = {
    background: 'white',
    width: '100%',
    marginTop: 'auto',
    border: 'none',
    borderRadius: '2px',
    alignItems: 'center',
    justifyContent: 'center',
  }

  return (
    <div className="select">
      <DropDown
        placeholder={select?.placeholder}
        DropDownStyle={DropDownStyles}
      >
        {select?.options?.map(({ id, text, onDeleteOption }) => (
          <div key={id} className="option">
            <div className="option-icon">{getIconText(text)}</div>
            <div className="option-text">{text}</div>
            {onDeleteOption && (
              <div className="option-delete" onClick={onDeleteOption}>
                <DeleteIcon />
              </div>
            )}
          </div>
        ))}
      </DropDown>
    </div>
  )
}

export default WorkflowSelect
