import { useEffect, useMemo, useRef, useState } from 'react'
import ArrowRightIcon from '../Icon/ArrowRight'
import Collapse from '../Collapse/Collapse'
import { Feature, PermissionTableProps } from './PermissionTable.d'
import { PermissionHeader } from './components/PermissionHeader/PermissionHeader'
import { PermissionRow } from './components/PermissionRow'

const PermissionTable = ({
  title,
  linkText,
  href,
  headerEditable,
  editable,
  deletable,
  features,
  roles,
  lang,
  onChange,
  t,
}: PermissionTableProps) => {
  const [isEditingTitle, setIsEditingTitle] = useState(false)
  const permissionTableContainer = useRef<HTMLDivElement>(null)

  const rolesFiltered = useMemo(
    () =>
      (roles || [])
        .filter(({ toDelete }) => !toDelete)
        .sort((a, b) => a.sortOrder - b.sortOrder),
    [roles]
  )

  useEffect(() => {
    permissionTableContainer.current?.style.setProperty(
      '--nb-columns',
      rolesFiltered.length.toString()
    )
  }, [rolesFiltered])

  const items = useMemo(() => {
    return Object.values(features as Record<string, Feature[]>)
      .map((features: Feature[]) => ({
        title: features[0].category,
        key: features[0].category,
        content: features
          .filter(feature => feature.global === true)
          .map(feature => (
            <PermissionRow
              editable={editable}
              key={feature.name}
              feature={feature}
              roles={rolesFiltered}
              setRoles={onChange}
            />
          )),
      }))
      .filter(item => item.content.length > 0)
  }, [features, rolesFiltered])

  return (
    <>
      {!!linkText && (
        <a className="permission-link" href={href} target="_blank">
          {linkText} <ArrowRightIcon />
        </a>
      )}

      <div className="permission-table" ref={permissionTableContainer}>
        <PermissionHeader
          roles={rolesFiltered}
          headerEditable={headerEditable}
          editable={editable}
          deletable={deletable}
          isEditingTitle={isEditingTitle}
          setIsEditingTitle={setIsEditingTitle}
          lang={lang}
          setRoles={onChange}
          title={title}
          t={t}
        />
        <Collapse
          accordion
          items={items.map(feature => feature)}
          onMoveItem={(from, to) => console.log(from, to)}
        />
      </div>
    </>
  )
}
export default PermissionTable
