import { FileText } from 'react-feather'
import { SVGProps } from 'react'

interface SVGRProps {
  title?: string
  titleId?: string
}

const FileTextIcon = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
      <FileText stroke="currentColor" />
    </div>
  )
}

export default FileTextIcon
