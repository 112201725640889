import { SVGProps } from 'react'
import { SVGRProps } from './interfaces'

const ConditionIcon = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M3 12H10" stroke="currentColor" />
    <path d="M17.1423 6H10.2852V18H17.1423" stroke="currentColor" />
    <circle cx="16" cy="18" r="2" fill="currentColor" />
    <circle cx="16" cy="6" r="2" fill="currentColor" />
    <circle cx="5" cy="12" r="2" fill="currentColor" />
  </svg>
)

export default ConditionIcon
