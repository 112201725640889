import { SVGProps } from 'react'

import { Settings } from 'react-feather'
import { SVGRProps } from './interfaces'

const SettingsIcon = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => {
  return (
    <div>
      <Settings />
    </div>
  )
}

export default SettingsIcon
