export const get = (url, responseType) =>
  new Promise(resolve => {
    const request = new XMLHttpRequest()

    request.withCredentials = true

    if (responseType) {
      request.responseType = responseType
    }

    request.open('GET', url, true)

    request.onload = function () {
      resolve(this.response)
    }

    request.send()
  })
