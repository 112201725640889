import { CSSProperties, ReactNode, FC } from 'react'
import classnames from 'classnames'

interface TagProps {
  type?:
    | 'selected'
    | 'base'
    | 'primary'
    | 'warning'
    | 'success'
    | 'draft'
    | 'error'
  size?: 'small' | 'default'
  className?: string
  style?: CSSProperties
  children?: ReactNode
}

export const Tag: FC<TagProps> = ({
  type,
  className,
  style,
  size = 'default',
  children,
  ...props
}) => {
  const classes = classnames('tag', `tag--${type}`, `tag--${size}`, className)

  return (
    <span className={classes} style={style} {...props}>
      {children}
    </span>
  )
}

export default Tag
