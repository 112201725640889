import { SVGProps } from 'react'
import { DollarSign } from 'react-feather'

import { SVGRProps } from './interfaces'

const DollarSignIcon = ({
  title,
  titleId,
  style,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => {
  return (
    <div style={{ display: 'flex', alignItems: 'stretch' }}>
      <DollarSign style={style} />
    </div>
  )
}

export default DollarSignIcon
