import React from 'react'

export interface SelectInputItem {
  title: string
  value: string
  options?: SelectInputItem[]
  className?: string
  isAdding?: boolean
  disabled?: boolean
  tooltip?: string
  icon?: () => React.ReactNode
  onClick?: () => void
}

export const sort = (sortingData: boolean, result: SelectInputItem[]) => {
  if (sortingData)
    result.sort(({ title }, other) =>
      (title || '').toString().localeCompare((other.title || '').toString())
    )
}

export function textContent(elem: null | React.ReactElement | string): string {
  if (!elem) {
    return ''
  }
  if (typeof elem === 'string') {
    return elem
  }
  const children = elem.props && elem.props.children
  if (children instanceof Array) {
    return children.map(textContent).join('')
  }
  return textContent(children)
}
