import { FC, CSSProperties } from 'react'

import LogoFooter from '../Illustration/LogoFooter'

export interface FooterStyle {
  footerStyle?: CSSProperties
}

export const Footer: FC<FooterStyle> = ({ footerStyle }) => {
  return (
    <footer style={footerStyle}>
      <span className="generalFoot">
        <span className="logoFoot">
          <LogoFooter />
        </span>
        <p className="textFooter">
          this platform is powered by alf{' '}
          <span style={{ color: 'red' }}>♥︎</span>
        </p>
      </span>
    </footer>
  )
}

export default Footer
