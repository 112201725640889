import { SVGProps } from 'react'

import { SVGRProps } from '../Icon/interfaces'

const Information = ({
  className,
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <div className={className}>
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.80143 14.6666C12.4833 14.6666 15.4681 11.6818 15.4681 7.99992C15.4681 4.31802 12.4833 1.33325 8.80143 1.33325C5.11953 1.33325 2.13477 4.31802 2.13477 7.99992C2.13477 11.6818 5.11953 14.6666 8.80143 14.6666Z"
        fill="currentColor"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.86133 5.99989C7.01806 5.55434 7.32743 5.17863 7.73463 4.93931C8.14183 4.7 8.62059 4.61252 9.08611 4.69237C9.55163 4.77222 9.97387 5.01424 10.278 5.37558C10.5822 5.73691 10.7487 6.19424 10.748 6.66656C10.748 7.99989 8.74799 8.66656 8.74799 8.66656"
        fill="currentColor"
      />
      <path
        d="M6.86133 5.99989C7.01806 5.55434 7.32743 5.17863 7.73463 4.93931C8.14183 4.7 8.62059 4.61252 9.08611 4.69237C9.55163 4.77222 9.97387 5.01424 10.278 5.37558C10.5822 5.73691 10.7487 6.19424 10.748 6.66656C10.748 7.99989 8.74799 8.66656 8.74799 8.66656"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.80078 11.3333H8.80745"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </div>
)

export default Information
