export const click = (href, method, after) => {
    const link = document.createElement('a')

    link.href = href

    if (method) {
      method(link)

      document.body.appendChild(link)
    }

    link.click()

    if (after) after(link)

    if (method) document.body.removeChild(link)
  },
  object = (content, type = 'pdf') => {
    click(
      URL.createObjectURL(content),
      link => {
        link.download = 'data.' + type
      },
      link => URL.revokeObjectURL(link.href)
    )
  },
  core = (content, type = 'csv') => {
    object(new Blob([content], { type: 'text/plain' }), type)
  },
  download = (content, ...parameters) => {
    core(JSON.stringify(content, ...parameters), 'json')
  }
