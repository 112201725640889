import {
  useRef,
  useEffect,
  FC,
  ChangeEvent,
  useState,
  FocusEvent,
  KeyboardEvent,
} from 'react'
import classnames from 'classnames'

import PlusIcon from '../Icon/PlusIcon'
import CancelIcon from '../Icon/CancelIcon'
import EnterIcon from '../Icon/EnterIcon'

interface EmailInputProps {
  onChange?: (value: string[]) => void
  placeholder?: string
  value?: string[]
  disabled?: boolean
  readOnly?: boolean
}

export const EmailInput: FC<EmailInputProps> = ({
  onChange,
  value = [],
  placeholder,
  disabled,
  readOnly = false,
}) => {
  const [isAdding, setIsAdding] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [width, setWidth] = useState(0)
  const span = useRef<HTMLSpanElement>(null)

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target?.value || '')
  }

  const closeInput = () => {
    setInputValue('')
    setIsAdding(false)
  }

  const setEmail = () => {
    if (inputValue.length > 0 && isValidEmail(inputValue)) {
      typeof onChange === 'function' && onChange([...value, inputValue])
      closeInput()
    }
  }

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.code === 'Space') {
      setEmail()
      setIsAdding(true)
      e.preventDefault()
    }
    if (e.code === 'Tab') {
      setEmail()
    }
    if (e.code === 'Enter') {
      setEmail()
      e.preventDefault()
    }
  }

  const onInputBlur = (e: FocusEvent<HTMLDivElement>) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      closeInput()
    }
  }

  useEffect(() => {
    const offsetWidth =
      typeof span?.current?.offsetWidth === 'number'
        ? span?.current?.offsetWidth
        : 0
    setWidth(offsetWidth + 15)
  }, [inputValue])

  const isValidEmail = (email: string) => {
    const reg =
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
    return reg.test(email)
  }

  const removeEmail = (index: number) => {
    const val = [...value]
    val.splice(index, 1)
    typeof onChange === 'function' && onChange(val)
  }

  return (
    <div
      className={classnames('email-input', disabled && 'email-input--disabled')}
    >
      {value.map((val, index) => (
        <div key={index} className="email-input-value">
          {val}
          {!readOnly && (
            <div
              className="email-input-remove"
              onClick={() => removeEmail(index)}
            >
              <CancelIcon />
            </div>
          )}
        </div>
      ))}
      {!readOnly && (
        <>
          {isAdding ? (
            <div
              className="email-input-field"
              tabIndex={0}
              onBlur={onInputBlur}
            >
              <span className="email-input-span" ref={span}>
                {inputValue}
              </span>
              <input
                title="input"
                autoFocus
                value={inputValue}
                style={{ width }}
                type="email"
                onChange={handleInputChange}
                onKeyDown={handleKeyPress}
              />
              <div className="email-input-enter" onClick={setEmail}>
                <EnterIcon />
              </div>
            </div>
          ) : (
            <div
              className="email-input-placeholder"
              onClick={() => setIsAdding(true)}
            >
              <div className="email-input-add">
                <PlusIcon />
              </div>
              {placeholder}
            </div>
          )}
        </>
      )}
    </div>
  )
}
