import { Info } from 'react-feather'
import { SVGProps } from 'react'

interface SVGRProps {
  title?: string
  titleId?: string
}

const InfoFilledIcon = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <div style={{ display: 'flex', alignItems: 'stretch' }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="800px"
      height="800px"
      viewBox="1 1 22 22"
      fill="none"
    >
      <g clip-path="url(#clip0_429_11160)">
        <circle
          cx="12"
          cy="11.9999"
          r="9"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect
          x="12"
          y="8"
          width="0.01"
          height="0.01"
          stroke="currentColor"
          strokeLinejoin="round"
        />
        <path
          d="M12 12V16"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_429_11160">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </div>
)

export default InfoFilledIcon
