import { SVGProps } from 'react'
import { Mail } from 'react-feather'
import { SVGRProps } from './interfaces'

const MailIcon = ({
  title,
  titleId,
  style,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => {
  return (
    <div>
      <Mail stroke="currentColor" style={style} />
    </div>
  )
}

export default MailIcon
