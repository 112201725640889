import { Box } from 'react-feather'
import { SVGProps } from 'react'

interface SVGRProps {
  title?: string
  titleId?: string
}

const BoxIcon = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => {
  return (
    <div style={{ display: 'flex', alignItems: 'stretch' }}>
      <Box />
    </div>
  )
}

export default BoxIcon
