import { CSSProperties, ChangeEvent } from 'react'

interface SquareCheckboxProps {
  label?: string
  disabled?: boolean
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  checked: boolean
  name?: string
  styleCheckbox?: CSSProperties
}

export const SquareCheckbox = ({
  label,
  disabled,
  onChange,
  checked,
  name,
  styleCheckbox,
}: SquareCheckboxProps) => {
  return (
    <label style={styleCheckbox} className="checkbox-container">
      <input
        name={name}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        type="checkbox"
        data-testid="square-checkbox-test"
      />
      <div className="checkmark"></div>
      {label ? <span className="label">{label}</span> : null}
    </label>
  )
}
