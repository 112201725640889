import { ReactNode, Dispatch, FocusEvent, FC, useMemo, useState } from 'react'
import classNames from 'classnames'
import Eye from '../Icon/EyePasswordIcon'
import EyeOff from '../Icon/EyeOffPasswordIcon'
import { RenderError } from './RenderError'

export type AuthorizationError = {
  submitError?: boolean
  textSubmitError: string | ReactNode
  textValidateError: string | ReactNode
  validationError?: boolean
}

interface AuthorizationFieldProps {
  placeholder: string
  value: string
  error: AuthorizationError
  stateChange: Dispatch<string>
  disableValidateStyle: boolean
  passwordEye: boolean
  type: string
  onBlur: (evt: FocusEvent<HTMLInputElement>) => void
  disableTooltip: boolean
}

export const AuthorizationField: FC<AuthorizationFieldProps> = ({
  placeholder,
  value,
  error,
  stateChange,
  disableValidateStyle,
  passwordEye,
  type,
  onBlur,
  disableTooltip,
}) => {
  const [inputType, setInputType] = useState(type)
  const [inputFocus, setInputFocus] = useState(false)
  const isInvalid = useMemo(
    () => !error?.validationError || error?.submitError,
    [error]
  )
  const isValid = useMemo(() => error?.validationError, [error])

  const getClassNames = (className: string) => {
    if (disableValidateStyle || !value.length || inputFocus) {
      return className
    }
    return classNames(className, { valid: isValid }, { invalid: isInvalid })
  }

  return (
    <div className={getClassNames('sign-in-form-input-container')}>
      <input
        title="input"
        onBlur={(evt: FocusEvent<HTMLInputElement>) => {
          typeof onBlur === 'function' && onBlur(evt)
          setInputFocus(false)
        }}
        onFocus={() => setInputFocus(true)}
        className={getClassNames('sign-in-form-input')}
        placeholder=" "
        type={inputType}
        onChange={event => stateChange(event.target.value)}
        value={value}
        data-testid="input-test"
      />
      <label htmlFor="email-input" className="sign-in-form-input-label">
        {placeholder}
      </label>
      <div className="sign-in-form-input-extra">
        <RenderError
          {...{
            error,
            disableValidateStyle,
            value,
            disableTooltip,
            inputFocus,
          }}
        />
        {passwordEye && (
          <div className="recover-password">
            {inputType === 'password' ? (
              <div onClick={() => setInputType('text')}>
                <EyeOff />
              </div>
            ) : (
              <div onClick={() => setInputType('password')}>
                <Eye style={{ marginLeft: '10px' }} />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
export default AuthorizationField
