import { useRef, useImperativeHandle, useEffect, forwardRef, memo } from 'react'
import { DocumentEditorContainerComponent } from '@syncfusion/ej2-react-documenteditor'
import { DocumentEditor } from '../../DocumentEditor/DocumentEditor'

interface DocumentMainProps {
  enableToolbar?: boolean
  enableAutoFocus?: boolean
  restrictEditing?: boolean
  [key: string]: any // Allow additional props for flexibility
}

// Correct typing with forwardRef
export const DocumentMain = memo(
  forwardRef<DocumentEditorContainerComponent, DocumentMainProps>(
    (properties, reference) => {
      const ref = useRef<DocumentEditorContainerComponent>(null!) // Non-null assertion (!)

      // Forward the ref to the parent using useImperativeHandle
      useImperativeHandle(reference, () => ref.current)

      useEffect(() => {
        setTimeout(() => {
          // Ensure the ref is defined before using it
          ref.current?.documentEditor?.fitPage('FitOnePage')
        }, 0)
      }, [])

      return (
        <DocumentEditor
          ref={ref} // Forward the ref to the DocumentEditor component
          enableToolbar={false}
          enableAutoFocus={false}
          restrictEditing={true}
          {...properties} // Pass any other props received by DocumentMain to DocumentEditor
        />
      )
    }
  )
)
