import { ReactNode, FC } from 'react'

export type WorkflowBodyProps = {
  name: string
  description: string
  children?: ReactNode
}

export const WorkflowBody: FC<WorkflowBodyProps> = ({
  name,
  description,
  children,
}) => (
  <div className="product-card-body">
    {!!name && <p className="product-card-name">{name}</p>}
    {!!description && (
      <p className="product-card-position-name">{description}</p>
    )}
    {children}
  </div>
)

export default WorkflowBody
