import React, { useEffect, useRef, useState } from 'react'
import './EllipsisTooltip.less'
import classNames from 'classnames'
import { Tooltip, TooltipProps } from '../Tooltip/Tooltip'

interface EllipsisTooltipProps extends TooltipProps {
  limit?: number
  line?: number
}

export const EllipsisTooltip: React.FC<EllipsisTooltipProps> = ({
  children,
  limit = 200,
  line,
  placement,
}) => {
  const [trim, setTrim] = useState(false)
  const [lined, setLined] = useState(false)
  const ref = useRef<HTMLSpanElement | null>(null)

  useEffect(() => {
    if (trim && line && ref.current) {
      const rects = ref.current.getClientRects()
      if (rects.length > line) {
        setLined(true)
      }
    }
  }, [trim, line])

  useEffect(() => {
    if (ref.current?.offsetWidth && ref.current.offsetWidth > limit) {
      setTrim(true)
    }
  }, [limit])

  return (
    <Tooltip
      title={children}
      placement={placement}
      styles={{
        root: {
          visibility: trim && (!line || lined) ? 'visible' : 'hidden',
        },
      }}
    >
      <span
        ref={ref}
        className={classNames(
          'ellipsis-tooltip',
          line && `ellipsis-tooltip--${line}lined`
        )}
        style={{
          maxWidth: trim ? `${limit}px` : 'initial',
          whiteSpace: line ? 'normal' : 'unset',
        }}
      >
        {children}
      </span>
    </Tooltip>
  )
}
