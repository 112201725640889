import { ReactNode, CSSProperties, FC } from 'react'
import classnames from 'classnames'

export type ProfileCardProps = {
  leftAvatarIcon: ReactNode
  ProfileFooter: ReactNode
  children?: ReactNode
  className?: string
  style?: CSSProperties
}

export const Profile: FC<ProfileCardProps> = ({
  className,
  style,
  leftAvatarIcon,
  ProfileFooter,
  children,
  ...rest
}) => (
  <div
    className={classnames('profile-card', className)}
    style={style}
    {...rest}
  >
    <div className="profile-card-content">
      <div className="profile-card-avatar">{leftAvatarIcon}</div>
      <div className="profile-card-body">{children}</div>
    </div>
    <div className="profile-card-footer">{ProfileFooter}</div>
  </div>
)

export default Profile
