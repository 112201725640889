import { useRef, useState } from 'react'

export const usePDFProps = ({
  ratio = 0.6,
  maxHeight = 1000,
  onLoad,
}: {
  ratio?: number
  maxHeight?: number
  onLoad?: (data: any) => void // Optional
}) => {
  const [page, setPage] = useState(1)
  const [scale, setScale] = useState(1)
  const [pages, setPages] = useState<number | null>(null)
  const [pageWidth, setPageWidth] = useState<number | null>(null)
  const [pageHeight, setPageHeight] = useState<number | null>(null)
  const [containerHeight, setContainerHeight] = useState<number | null>(null)

  const ref = useRef<HTMLDivElement | null>(null)

  return {
    page,
    setPage,
    scale,
    setScale,
    pages,
    setPages,
    pageWidth,
    setPageWidth,
    pageHeight,
    setPageHeight,
    containerHeight,
    setContainerHeight,
    ref,

    onSetScale: async (type: boolean) => {
      let newScale = scale + (type ? 0.1 : -0.1)

      if (newScale > 2) {
        newScale = 2
      } else if (newScale < 0.1) {
        newScale = 0.1
      }

      setScale(newScale)
    },

    onDocumentComplete: async (pdf: any) => {
      const pageElm = await pdf.getPage(page)
      const { clientWidth } = ref.current!

      let finalWidth = pageElm.view[2]
      let finalHeight = pageElm.view[3]

      if (clientWidth < pageElm.view[2]) {
        finalWidth = clientWidth * ratio
        setPageWidth(finalWidth)
      } else if (maxHeight && maxHeight < pageElm.view[3]) {
        finalHeight = maxHeight * ratio
        setPageHeight(finalHeight)
      }

      setContainerHeight(finalHeight + 20)
      setPages(pdf.numPages)

      if (typeof onLoad === 'function') {
        onLoad({ margin: (clientWidth - finalWidth) / 2 })
      }
    },

    onPage: (type: number) => {
      let newPage = page + (type ? 1 : -1)

      if (pages && newPage > pages) {
        newPage = 1
      } else if (newPage < 1) {
        newPage = pages!
      }

      setPage(newPage)
    },
  }
}
