import { CSSProperties, useRef, MutableRefObject } from 'react'
import DownloadIcon from '../Icon/DownloadIcon'
import InnerButton from '../InnerButton/InnerButton'

interface UploadButtonProps {
  onChange: () => void
  style?: CSSProperties
  accept?: string
}

export const UploadButton = ({
  onChange,
  style,
  accept = 'json',
  ...properties
}: UploadButtonProps) => {
  const ref = useRef() as MutableRefObject<HTMLInputElement>

  return (
    <>
      <InnerButton
        className="upload-button"
        icon={<DownloadIcon />}
        {...{ style, ...properties }}
        onClick={() => ref.current.click()}
      />
      <input {...{ ref, onChange }} hidden type="file" accept={'.' + accept} />
    </>
  )
}
