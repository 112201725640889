import { useMemo } from 'react'
import PlusIcon from '../../../Icon/PlusIcon'
import InnerButton, { ButtonType } from '../../../InnerButton/InnerButton'
import { PermissionHeaderProps } from '../../PermissionTable.d'
import { PermissionRoleHeader } from './PermissionRoleHeader'

export const PermissionHeader = ({
  roles,
  headerEditable,
  editable,
  isEditingTitle,
  deletable,
  lang,
  setIsEditingTitle,
  setRoles,
  title,
  t,
}: PermissionHeaderProps) => {
  const RolesHeader = useMemo(() => {
    return roles.map((role, i) => (
      <PermissionRoleHeader
        key={`title-${i}`}
        role={role}
        index={i}
        lang={lang}
        headerEditable={headerEditable}
        editable={editable}
        deletable={role.sortOrder !== 0 && deletable}
        isEditingTitle={isEditingTitle}
        setIsEditingTitle={setIsEditingTitle}
        roles={roles}
        setRoles={setRoles}
      />
    ))
  }, [roles, lang, isEditingTitle, editable, setRoles])

  const onAddColumn = () => {
    setRoles([
      ...roles,
      {
        sortOrder: roles.length,
        title: { FR: 'new role', EN: 'new role' },
        features: [],
      },
    ])
  }

  return (
    <div className="permission-row title-row">
      <div className="permission-column">
        {editable && (
          <InnerButton
            type={ButtonType.secondary}
            onClick={onAddColumn}
            icon={<PlusIcon />}
          >
            {t('userPermissions.addRole')}
          </InnerButton>
        )}
        {title && <div className="permission-title">{title}</div>}
      </div>
      <div className="role-columns">{RolesHeader}</div>
    </div>
  )
}
