import { FC, ReactNode, useState, useEffect } from 'react'
import classNames from 'classnames'
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd'
import { CollapseItem } from './CollapseItem'

interface CollapseProps {
  items: {
    icon?: ReactNode
    title: string
    key: string | number
    content: ReactNode
  }[]
  defaultActiveKey?: number | number[]
  activeKey?: number | number[]
  accordion?: boolean
  draggable?: boolean
  hideBorder?: boolean
  lazy?: boolean
  noGap?: boolean
  droppableId?: string | number
  onMoveItem: (
    from: number,
    to: number,
    fromKey?: string | number | undefined,
    targetKey?: string | number | undefined
  ) => void
  onAddItem?: (item: any, to: number) => void
  onChange?: (val?: (number | string | (number | string)[])[]) => void
}

export const Collapse: FC<CollapseProps> = ({
  items,
  activeKey,
  noGap = false,
  draggable = false,
  hideBorder = false,
  onMoveItem,
  accordion = false,
  lazy = false,
  droppableId,
  onChange,
}) => {
  const [collapsed, setCollapsed] = useState<(number | string)[]>(
    Array.isArray(activeKey)
      ? activeKey
      : activeKey === undefined
        ? []
        : [activeKey]
  )

  const handleInfoCollapseChange = (value: boolean, key: string | number) => {
    let result: (number | string)[]
    if (accordion) {
      result = value ? [key] : []
    } else {
      const values = [...collapsed]
      const indexOf = values.indexOf(key)
      value ? values.push(key) : values.splice(indexOf, 1)
      result = values
    }
    setCollapsed(result)
    typeof onChange === 'function' && onChange(result)
  }

  const handleSetCollapse = () => {
    const isSameArray = (a1: (number | string)[], a2: (number | string)[]) =>
      a1.length === a2.length &&
      a1.every((element, index) => element === a2[index])

    if (Array.isArray(activeKey) && Array.isArray(collapsed)) {
      if (isSameArray(activeKey, collapsed)) {
        return
      }
    }

    if (activeKey) {
      setCollapsed(Array.isArray(activeKey) ? [activeKey[0]] : [activeKey])
    } else {
      setCollapsed([])
    }
  }

  useEffect(() => {
    handleSetCollapse()
  }, [activeKey])

  useEffect(() => {
    handleSetCollapse()
  }, [])

  const onDragStart = () => {
    setCollapsed && setCollapsed([])
  }

  const handleMove = (evt: DropResult) => {
    onMoveItem(
      evt.source?.index,
      evt.destination?.index || 0,
      items[evt.source.index].key,
      evt.destination ? items[evt.destination.index].key : undefined
    )
  }

  return (
    <div className={classNames('collapse', noGap && 'collapse--nogap')}>
      <DragDropContext onDragEnd={handleMove} onDragStart={onDragStart}>
        <Droppable droppableId={(droppableId || 'list').toString()}>
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              className={classNames(
                'list',
                snapshot.isDraggingOver && 'draggingOver'
              )}
              {...provided.droppableProps}
            >
              {items.map((item, index) => {
                const key = item.key ?? index
                return (
                  <CollapseItem
                    {...{ draggable, hideBorder, index }}
                    key={key}
                    icon={item.icon}
                    title={item.title}
                    collapsed={collapsed.includes(key)}
                    onChange={val => handleInfoCollapseChange(!!val, key)}
                  >
                    {(!lazy || collapsed.includes(key)) && item.content}
                  </CollapseItem>
                )
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  )
}

export default Collapse
