import { SVGProps } from 'react'

interface SVGRProps {
  title?: string
  titleId?: string
}

const Target = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => {
  return (
    <div>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="12" cy="12" r="12" fill="currentColor" fill-opacity="0.2" />
        <ellipse
          cx="11.9987"
          cy="11.9999"
          rx="8.1081"
          ry="8.1081"
          fill="currentColor"
          fill-opacity="0.4"
        />
        <ellipse
          cx="12.0014"
          cy="11.9999"
          rx="4.21621"
          ry="4.21622"
          fill="currentColor"
        />
      </svg>
    </div>
  )
}

export default Target
