import classNames from 'classnames'
import { SelectInputItem } from './helpers'
import CheckIcon from '../Icon/CheckIcon'
import { Tooltip } from '../Tooltip/Tooltip'

interface RowProps {
  index: number | string
  option: SelectInputItem
  value?: null | string | string[]
  handleChangeOption: any
  renderOption: any
  setDatalistDisplay?: any
}

export const SelectRow = ({
  index,
  option,
  value,
  handleChangeOption,
  renderOption,
}: RowProps) => (
  <li
    key={index}
    className={classNames(
      'dropdown-select__list-item',
      option.className,
      option.isAdding && 'dropdown-select__list-item--adding',
      value === option.value && 'dropdown-select__list-item--selected',
      option.disabled && 'dropdown-select__list-item--disabled'
    )}
    onClick={() => !option.disabled && handleChangeOption(option.value)}
  >
    {option.tooltip ? (
      <Tooltip title={option.tooltip}>{renderOption(option)}</Tooltip>
    ) : (
      renderOption(option)
    )}
  </li>
)

export const SelectRowMultiple = ({
  index,
  option,
  value,
  handleChangeOption,
  setDatalistDisplay,
  renderOption,
}: RowProps) => {
  const isSelected =
    Array.isArray(value) && value.findIndex(val => val === option.value) >= 0
  const handleClick = () => {
    if (option.disabled) {
      return
    }
    if (typeof option.onClick === 'function') {
      option.onClick()
      setDatalistDisplay(false)
    } else {
      handleChangeOption(option.value)
    }
  }
  return (
    <li
      key={index}
      className={classNames(
        'dropdown-select__list-item',
        option.className,
        option.isAdding && 'dropdown-select__list-item--adding',
        isSelected && 'dropdown-select__list-item--selected',
        option.disabled && 'dropdown-select__list-item--disabled'
      )}
      onClick={handleClick}
    >
      <div className="dropdown-select__list-item-multiple">
        {option.tooltip ? (
          <Tooltip title={option.tooltip}>{renderOption(option)}</Tooltip>
        ) : (
          renderOption(option)
        )}

        {isSelected && <CheckIcon />}
      </div>
    </li>
  )
}
