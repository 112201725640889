import { SVGProps } from 'react'

import { SVGRProps } from './interfaces'

const Happy = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="3 3 18 18">
      <rect
        width="17"
        height="17"
        x="3.5"
        y="3.5"
        stroke="currentColor"
        rx="1.5"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M15 10H15.01"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M7.61406 10.3587 10.4505 10.1477C10.7656 10.1238 11.0021 9.84891 10.9788 9.53219 10.9554 9.21593 10.6801 8.98058 10.3668 9.00127L7.5299 9.21226C7.2152 9.23616 6.97826 9.51105 7.00158 9.82776 7.024 10.1298 7.27512 10.3601 7.57152 10.3601 7.5857 10.3601 7.59988 10.3596 7.61406 10.3587ZM15.1578 14.1427C15.1523 14.1473 14.8422 14.3969 14.2365 14.6497 13.7224 14.8643 12.9988 15.0813 12.0364 15.153 11.214 15.2123 10.4688 15.0689 9.90577 14.8993 9.27408 14.709 8.87156 14.4865 8.85784 14.4782 8.58476 14.321 8.2353 14.4134 8.07749 14.6883 7.91877 14.9623 8.01163 15.3139 8.28516 15.4734 8.34828 15.5106 9.75482 16.3165 11.6389 16.3165 11.7962 16.3165 11.9573 16.3114 12.121 16.299 14.6285 16.1124 15.8594 15.0523 15.9107 15.0073 16.1485 14.7986 16.1728 14.4355 15.9646 14.1964 15.757 13.9579 15.3956 13.9326 15.1578 14.1427Z"
        clipRule="evenodd"
      />
    </svg>
  )
}

export default Happy
