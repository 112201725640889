import { SVGProps } from 'react'
import { SVGRProps } from '../Icon/interfaces'

const Baby = ({
  style,
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <div>
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27 10H9C8.44772 10 8 10.4477 8 11V29C8 29.5523 8.44772 30 9 30H27C27.5523 30 28 29.5523 28 29V11C28 10.4477 27.5523 10 27 10ZM9 8C7.34315 8 6 9.34315 6 11V29C6 30.6569 7.34315 32 9 32H27C28.6569 32 30 30.6569 30 29V11C30 9.34315 28.6569 8 27 8H9Z"
        fill="#3AC9A6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5742 23.738C11.719 23.5028 12.0269 23.4295 12.2621 23.5742L12.0001 24.0001C12.2621 23.5742 12.262 23.5742 12.2618 23.5741L12.2617 23.574C12.2618 23.574 12.2618 23.5741 12.2619 23.5741L12.2641 23.5754L12.2753 23.5822C12.2858 23.5885 12.3024 23.5983 12.3248 23.6112C12.3696 23.6372 12.4377 23.6757 12.5273 23.724C12.7065 23.8205 12.9711 23.9557 13.3066 24.1065C13.9787 24.4086 14.9293 24.7705 16.0432 25.0114C18.273 25.4935 21.1108 25.4831 23.706 23.5957C23.9293 23.4333 24.242 23.4827 24.4044 23.706C24.5669 23.9293 24.5175 24.242 24.2942 24.4044C21.3893 26.517 18.2271 26.5067 15.8319 25.9888C14.6334 25.7296 13.6152 25.3416 12.8967 25.0186C12.5368 24.8569 12.2507 24.7109 12.0529 24.6043C11.954 24.551 11.8771 24.5075 11.824 24.4768C11.7975 24.4614 11.7769 24.4493 11.7625 24.4407L11.7455 24.4305L11.7406 24.4275L11.739 24.4265L11.7384 24.4262C11.7382 24.426 11.738 24.4259 12.0001 24.0001L11.738 24.4259C11.5028 24.2812 11.4295 23.9732 11.5742 23.738ZM12.2617 23.574L12.2617 23.574L12.2617 23.574Z"
        fill="#3AC9A6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0025 16.9503C12.03 16.6755 12.275 16.475 12.5498 16.5025L17.5498 17.0025C17.8246 17.03 18.025 17.275 17.9976 17.5498C17.9701 17.8246 17.7251 18.025 17.4503 17.9976L12.4503 17.4976C12.1755 17.4701 11.975 17.2251 12.0025 16.9503Z"
        fill="#3AC9A6"
      />
      <path
        d="M24 18C24 18.5523 23.5523 19 23 19C22.4477 19 22 18.5523 22 18C22 17.4477 22.4477 17 23 17C23.5523 17 24 17.4477 24 18Z"
        fill="#3AC9A6"
      />
    </svg>
  </div>
)
export default Baby
