import { SVGProps } from 'react'

interface SVGRProps {
  title?: string
  titleId?: string
}

const AttachmentCut = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => {
  return (
    <svg
      viewBox="0 0 19 26"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="butt"
      strokeLinejoin="round"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M -3.963 -12.437 C -7.231 -9.169 -12.811 -10.664 -14.007 -15.128 C -14.562 -17.2 -13.97 -19.41 -12.453 -20.927 L -3.263 -30.117 C -1.084 -32.296 2.635 -31.299 3.433 -28.323 C 3.803 -26.942 3.408 -25.468 2.397 -24.457 L -6.803 -15.267 C -7.892 -14.178 -9.752 -14.676 -10.151 -16.164 C -10.336 -16.855 -10.139 -17.591 -9.633 -18.097 L -1.494 -26.224 C -2.555 -25.177 -2.216 -25.518 -2.549 -25.173"
        transform="matrix(-0.719339907169342, 0.694658100605011, -0.694658100605011, -0.719339907169342, -7.808679580688477, 1.2309097051620483)"
      />
    </svg>
  )
}

export default AttachmentCut
