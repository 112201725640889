import { SVGProps } from 'react'

import { Plus } from 'react-feather'

import { SVGRProps } from './interfaces'

const PlusIcon = (properties: SVGProps<SVGSVGElement> & SVGRProps) => (
  <Plus {...properties} />
)

export default PlusIcon
