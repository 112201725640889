import { SVGProps } from 'react'
import { SVGRProps } from './interfaces'

const Analyze = ({
  title,
  titleId,
  style,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <div style={{ display: 'flex', alignItems: 'stretch' }}>
    <svg
      width="16"
      height="16"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <circle
        cx="4.5"
        cy="19.5"
        r="2"
        transform="rotate(-90 4.5 19.5)"
        stroke="currentColor"
      />
      <circle
        cx="9.5"
        cy="8.5"
        r="2"
        transform="rotate(-90 9.5 8.5)"
        stroke="currentColor"
      />
      <circle
        cx="16.5"
        cy="17.5"
        r="2"
        transform="rotate(-90 16.5 17.5)"
        stroke="currentColor"
      />
      <circle
        cx="19.5"
        cy="4.5"
        r="2"
        transform="rotate(-90 19.5 4.5)"
        stroke="currentColor"
      />
      <line
        x1="5.53184"
        y1="17.8244"
        x2="8.53184"
        y2="9.82444"
        stroke="currentColor"
      />
      <line
        x1="10.3841"
        y1="9.67991"
        x2="15.3841"
        y2="15.6799"
        stroke="currentColor"
      />
      <line
        x1="17.5119"
        y1="15.8915"
        x2="19.5119"
        y2="6.89153"
        stroke="currentColor"
      />
    </svg>
  </div>
)

export default Analyze
