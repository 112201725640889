import React, { ReactNode, useState, useRef, CSSProperties } from 'react'
import useOnClickOutside from '../../hooks/useOnClickOutside'
import { SelectContext } from './DropDownContext'

export interface DropDownProps {
  children: ReactNode | ReactNode[]
  defaultValue?: string
  placeholder?: string
  label?: string
  DropDownStyle?: CSSProperties
}

export const DropDown: React.FC<DropDownProps> = ({
  children,
  defaultValue,
  label,
  placeholder,
  DropDownStyle,
}) => {
  const [selectedOption, setSelectedOption] = useState(defaultValue || '')
  const [showDropdown, setShowDropdown] = useState(false)
  const showDropdownHandler = () => setShowDropdown(!showDropdown)
  const selectPlaceholder = placeholder || 'Choose an option'
  const selectContainerRef = useRef(null)

  const clickOutsideHandler = () => setShowDropdown(false)

  useOnClickOutside(selectContainerRef, clickOutsideHandler)

  const updateSelectedOption = (option: string) => {
    setSelectedOption(option)
    setShowDropdown(false)
  }

  return (
    <SelectContext.Provider
      value={{ selectedOption, changeSelectedOption: updateSelectedOption }}
    >
      <div
        className="select-container"
        style={DropDownStyle}
        ref={selectContainerRef}
      >
        <div className="label-text">{label}</div>
        <div
          className={showDropdown ? 'selected-text active' : 'selected-text'}
          onClick={showDropdownHandler}
        >
          {selectedOption.length > 0 ? selectedOption : selectPlaceholder}
        </div>
        <ul
          className={
            showDropdown
              ? 'select-options show-dropdown-options'
              : 'select-options hide-dropdown-options'
          }
        >
          {children}
        </ul>
      </div>
    </SelectContext.Provider>
  )
}

export default DropDown
