import { SVGProps } from 'react'
import { SVGRProps } from './interfaces'

const VariableIcon = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <div style={{ display: 'flex', alignItems: 'stretch' }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 1024 1024"
      version="1.1"
    >
      <path
        d="M988.598857 118.418286l7.094857 5.339428a25.307429 25.307429 0 0 1 4.681143 35.84l-374.125714 494.445715a71.094857 71.094857 0 0 1-28.233143 21.211428l-150.893714 54.125714c-14.409143 5.193143-26.916571-3.949714-26.550857-19.017142l3.876571-158.573715a61.074286 61.074286 0 0 1 11.629714-32.256L822.418286 23.332571c8.045714-10.24 24.064-12.434286 34.816-4.315428l131.364571 99.401143z m-497.883428 544.182857l90.697142-32.621714 276.187429-362.422858c6.070857-8.045714 4.900571-18.432-2.925714-24.137142l-76.288-55.808c-7.972571-5.851429-18.066286-3.437714-23.990857 4.096L479.817143 544.182857a35.986286 35.986286 0 0 0-7.241143 19.894857l-2.048 84.187429c-0.292571 11.410286 9.874286 18.066286 20.260571 14.336z m311.588571-533.796572a16.310857 16.310857 0 0 0 2.925714 23.552l75.264 54.930286a16.457143 16.457143 0 0 0 23.259429-3.657143l32.475428-42.861714c5.924571-7.899429 3.657143-18.285714-4.022857-24.137143l-71.533714-54.125714a17.334857 17.334857 0 0 0-24.356571 2.706286l-33.938286 43.593142z m148.845714 680.301715a164.132571 164.132571 0 0 1-164.352 164.278857H148.845714A131.291429 131.291429 0 0 1 18.285714 841.654857V187.977143c0-47.835429 38.473143-86.381714 86.601143-86.381714h494.006857c18.651429 0 33.792 15.286857 33.792 34.084571a33.938286 33.938286 0 0 1-33.792 34.084571H131.657143c-0.292571 0-0.292571 0-0.292572-19.309714l0.292572 19.309714c-22.674286 0-45.787429 27.501714-45.787429 51.346286v620.544c0 34.962286 28.306286 63.488 62.902857 63.488h628.077715c58.953143 0 106.715429-47.469714 106.715428-105.837714V470.747429c0-18.797714 15.067429-34.084571 33.792-34.084572s33.792 15.36 33.792 34.157714v338.285715z"
        fill="currentColor"
      />
    </svg>
  </div>
)

export default VariableIcon
