import { Grid } from 'react-feather'
import { SVGProps } from 'react'
import { SVGRProps } from './interfaces'

const GridIcon = ({
  title,
  titleId,
  style,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => {
  return <Grid strokeWidth="1.5" style={style} />
}

export default GridIcon
