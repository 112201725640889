import { SVGProps } from 'react'
import { SVGRProps } from './interfaces'

const Folders = ({
  title,
  titleId,
  style,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <div style={{ display: 'flex', alignItems: 'stretch' }}>
    <svg
      width="16"
      height="16"
      viewBox="0 0 24 24"
      fill="none"
      strokeWidth="0"
      stroke="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.8268 11H4.17397C3.58893 11 3.12884 11.5 3.17742 12.083L3.84409 20.083C3.88728 20.6013 4.32054 21 4.84063 21H19.1601C19.6802 21 20.1135 20.6013 20.1566 20.083L20.8233 12.083C20.8719 11.5 20.4118 11 19.8268 11ZM4.17397 10C3.00389 10 2.0837 11.0001 2.18087 12.1661L2.84754 20.1661C2.93392 21.2027 3.80046 22 4.84063 22H19.1601C20.2003 22 21.0668 21.2027 21.1532 20.1661L21.8199 12.1661C21.917 11.0001 20.9968 10 19.8268 10H4.17397Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.92003 7H18.0777C18.6594 7 19.1184 7.49458 19.075 8.07465L19.0057 9H20.0085L20.0722 8.14929C20.159 6.98915 19.2411 6 18.0777 6H5.92003C4.75665 6 3.83877 6.98915 3.92561 8.1493L3.98929 9H4.99209L4.92282 8.07465C4.8794 7.49458 5.33834 7 5.92003 7Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.93952 4H16.0571C16.6126 4 17.0577 4.45153 17.0572 5H18.0572C18.0583 3.90307 17.1681 3 16.0571 3H7.93952C6.82852 3 5.93833 3.90307 5.93945 5H6.93949C6.93892 4.45153 7.38402 4 7.93952 4Z"
        fill="currentColor"
      />
    </svg>
  </div>
)

export default Folders
