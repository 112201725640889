import { SVGProps } from 'react'
import { SVGRProps } from './interfaces'

const DragIcon = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="50"
    height="51"
    viewBox="0 0 50 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_10249_64049)">
      <path
        d="M11.9707 7.85938V42.743H37.6237V7.85938H11.9707ZM36.1822 41.2727H13.4122V9.32971H36.1822V41.2727Z"
        fill="currentColor"
      />
      <path
        d="M33.3405 36.2344H16.25V37.7047H33.3405V36.2344Z"
        fill="currentColor"
      />
      <path
        d="M33.3405 32.3984H16.25V33.8688H33.3405V32.3984Z"
        fill="currentColor"
      />
      <path
        d="M33.3417 28.5547H26.2207V30.025H33.3417V28.5547Z"
        fill="currentColor"
      />
      <path
        d="M33.3417 20.8828H26.2207V22.3531H33.3417V20.8828Z"
        fill="currentColor"
      />
      <path
        d="M17.6673 20.941V29.6571L20.5229 26.7414L23.38 29.6556V20.9395C24.2478 20.1396 24.8042 18.9942 24.8042 17.7106C24.8042 15.3022 22.8827 13.3438 20.5215 13.3438C18.1603 13.3438 16.2402 15.3022 16.2402 17.7106C16.2417 18.9957 16.7995 20.1411 17.6673 20.941ZM21.9399 26.1062L20.5244 24.6624L19.1103 26.1048V21.8143C19.5557 21.9746 20.0271 22.079 20.5244 22.079C21.0231 22.079 21.4945 21.9746 21.9399 21.8143V26.1062ZM20.5229 14.8156C22.0899 14.8156 23.3641 16.1153 23.3641 17.7121C23.3641 19.3104 22.0899 20.6101 20.5229 20.6101C18.9575 20.6101 17.6832 19.3104 17.6832 17.7121C17.6832 16.1139 18.9575 14.8156 20.5229 14.8156Z"
        fill="currentColor"
      />
      <path
        d="M33.3417 17.0469H26.2207V18.5172H33.3417V17.0469Z"
        fill="currentColor"
      />
      <path
        d="M33.3417 24.7188H26.2207V26.1891H33.3417V24.7188Z"
        fill="currentColor"
      />
      <path
        d="M33.3417 13.2031H26.2207V14.6735H33.3417V13.2031Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_10249_64049">
        <rect width="50" height="51" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default DragIcon
