import { SVGProps } from 'react'

import { Sliders } from 'react-feather'
import { SVGRProps } from './interfaces'

const SlidersIcon = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => {
  return (
    <div>
      <Sliders />
    </div>
  )
}

export default SlidersIcon
