import { endpoint } from './endpoint'

export const convert = file =>
  new Promise(resolve => {
    const request = new XMLHttpRequest()

    request.open('POST', endpoint + 'Import', true)

    const formData = new FormData()

    formData.append('files', file)

    request.onload = function () {
      resolve(this.response)
    }

    request.send(formData)
  })
