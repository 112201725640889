export default () => (
  <div style={{ display: 'flex', justifyContent: 'center' }}>
    <svg viewBox="0 0 23.77 19" height="20">
      <path
        style={{ fill: 'none', stroke: '#292929' }}
        d="M6.5.5h-4c-.53 0-1.04.21-1.41.59C.71 1.47.5 1.97.5 2.5v4m6-6h10c.53 0 1.04.21 1.41.59.38.38.59.88.59 1.41v4m-12-6v18m-6-12v10c0 .53.21 1.04.59 1.41.38.38.88.59 1.41.59h4m-6-12h18m0 0V10m-12 8.5h10m4-6.92v5.83m2.92-2.91-2.92 2.92-2.92-2.92"
      />
    </svg>
  </div>
)
