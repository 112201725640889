import { SVGProps } from 'react'
import { SVGRProps } from '../Icon/interfaces'

const Child = ({
  style,
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <div style={style}>
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.95703 9H28.044C28.5963 9 29.044 9.44771 29.044 10V15.9243L31.044 15.7762V10C31.044 8.34315 29.7008 7 28.044 7H7.95703C6.30018 7 4.95703 8.34315 4.95703 10V17.7085L6.95703 17.5604V10C6.95703 9.44772 7.40475 9 7.95703 9Z"
        fill="#FFBC39"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.04297 17.6231V28.9997C6.04297 30.6565 7.38612 31.9997 9.04297 31.9997H26.956C28.6129 31.9997 29.956 30.6565 29.956 28.9996V15.8184L27.956 15.9693V28.9996C27.956 29.5519 27.5083 29.9997 26.956 29.9997H9.04297C8.49068 29.9997 8.04297 29.5519 8.04297 28.9997V17.4722L6.04297 17.6231Z"
        fill="#FFBC39"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.8183 10.4004L17.5938 15.9515L29.9234 15.003C30.4741 14.9607 30.9548 15.3727 30.9971 15.9234C31.0395 16.4741 30.6274 16.9548 30.0768 16.9972L16.4064 18.0487L15.1819 15.5998L14.3539 18.0653L6.11204 18.9938C5.56322 19.0556 5.0682 18.6609 5.00638 18.112C4.94455 17.5632 5.33933 17.0682 5.88815 17.0064L12.8637 16.2206L14.8183 10.4004Z"
        fill="#FFBC39"
      />
      <path
        d="M23 18C23 18.5523 22.5523 19 22 19C21.4477 19 21 18.5523 21 18C21 17.4477 21.4477 17 22 17C22.5523 17 23 17.4477 23 18Z"
        fill="#FFBC39"
      />
      <path
        d="M12 21.5C12 21.7761 11.7761 22 11.5 22C11.2239 22 11 21.7761 11 21.5C11 21.2239 11.2239 21 11.5 21C11.7761 21 12 21.2239 12 21.5Z"
        fill="#FFBC39"
      />
      <path
        d="M26 21.5C26 21.7761 25.7761 22 25.5 22C25.2239 22 25 21.7761 25 21.5C25 21.2239 25.2239 21 25.5 21C25.7761 21 26 21.2239 26 21.5Z"
        fill="#FFBC39"
      />
      <path
        d="M14 20.5C14 20.7761 13.7761 21 13.5 21C13.2239 21 13 20.7761 13 20.5C13 20.2239 13.2239 20 13.5 20C13.7761 20 14 20.2239 14 20.5Z"
        fill="#FFBC39"
      />
      <path
        d="M24 20.5C24 20.7761 23.7761 21 23.5 21C23.2239 21 23 20.7761 23 20.5C23 20.2239 23.2239 20 23.5 20C23.7761 20 24 20.2239 24 20.5Z"
        fill="#FFBC39"
      />
      <path
        d="M12 19.5C12 19.7761 11.7761 20 11.5 20C11.2239 20 11 19.7761 11 19.5C11 19.2239 11.2239 19 11.5 19C11.7761 19 12 19.2239 12 19.5Z"
        fill="#FFBC39"
      />
      <path
        d="M26 19.5C26 19.7761 25.7761 20 25.5 20C25.2239 20 25 19.7761 25 19.5C25 19.2239 25.2239 19 25.5 19C25.7761 19 26 19.2239 26 19.5Z"
        fill="#FFBC39"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5742 24.738C11.719 24.5028 12.0269 24.4295 12.2621 24.5742L12.0001 25.0001C12.2621 24.5742 12.262 24.5742 12.2618 24.5741L12.2617 24.574C12.2618 24.574 12.2618 24.5741 12.2619 24.5741L12.2641 24.5754L12.2753 24.5822C12.2858 24.5885 12.3024 24.5983 12.3248 24.6112C12.3696 24.6372 12.4377 24.6757 12.5273 24.724C12.7065 24.8205 12.9711 24.9557 13.3066 25.1065C13.9787 25.4086 14.9293 25.7705 16.0432 26.0114C18.273 26.4935 21.1108 26.4831 23.706 24.5957C23.9293 24.4333 24.242 24.4827 24.4044 24.706C24.5669 24.9293 24.5175 25.242 24.2942 25.4044C21.3893 27.517 18.2271 27.5067 15.8319 26.9888C14.6334 26.7296 13.6152 26.3416 12.8967 26.0186C12.5368 25.8569 12.2507 25.7109 12.0529 25.6043C11.954 25.551 11.8771 25.5075 11.824 25.4768C11.7975 25.4614 11.7769 25.4493 11.7625 25.4407L11.7455 25.4305L11.7406 25.4275L11.739 25.4265L11.7384 25.4262C11.7382 25.426 11.738 25.4259 12.0001 25.0001L11.738 25.4259C11.5028 25.2812 11.4295 24.9732 11.5742 24.738ZM12.2617 24.574L12.2617 24.574L12.2617 24.574Z"
        fill="#FFBC39"
      />
    </svg>
  </div>
)

export default Child
