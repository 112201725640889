import { SVGProps } from 'react'
import { SVGRProps } from './interfaces'

const WorldIcon = ({
  title,
  titleId,
  style,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <div style={{ display: 'flex', alignItems: 'stretch', cursor: 'pointer' }}>
    <svg
      style={style}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" />
      <path
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 12H22"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 2C14.5013 4.73835 15.9228 8.29203 16 12C15.9228 15.708 14.5013 19.2616 12 22C9.49872 19.2616 8.07725 15.708 8 12C8.07725 8.29203 9.49872 4.73835 12 2V2Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <clipPath id="clip0_0_1">
          <rect
            width="1440"
            height="3612"
            fill="white"
            transform="translate(-1384 -23)"
          />
        </clipPath>
      </defs>
    </svg>
  </div>
)

export default WorldIcon
