import React from 'react'

export type WorkflowStatsProps = {
  stats?: {
    name: string
    value: number | string
  }[]
  color: string
}

export const WorkflowStats: React.FC<WorkflowStatsProps> = ({
  stats,
  color,
}) => {
  return (
    <div className="product-card-stats">
      {stats?.map(stat => {
        return stat.name && (stat.value || !stat.value) ? (
          <div key={stat.name} className="stat-container">
            <div className="stat-value" style={{ color }}>
              {stat.value}
            </div>
            <div className="stat-name">{stat.name}</div>
          </div>
        ) : null
      })}
    </div>
  )
}

export default WorkflowStats
