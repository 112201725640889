import { ChangeEvent, ReactNode } from 'react'

interface RadioBtnProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  name: string
  value: string
  disabled?: boolean
  checked?: boolean
}

const RadioBtn = ({
  name,
  value,
  onChange,
  disabled,
  checked,
}: RadioBtnProps) => {
  return (
    <label className="radio-container">
      <input
        checked={checked}
        disabled={disabled}
        value={value}
        onChange={onChange}
        type="radio"
        name={name}
      />
      <div className="checkmark"></div>
      <span className="radio-value">{value}</span>
    </label>
  )
}

interface RadioBtnGroupProps {
  children: ReactNode | ReactNode[]
  label: string
  required?: boolean
}

export const RadioBtnGroup = ({
  children,
  label,
  required,
}: RadioBtnGroupProps) => {
  return (
    <div className="radio-group">
      <div className="radio-label">
        {required ? <span className="required">*</span> : null}
        {label}
      </div>
      <div className="radio-btn-wrap">{children}</div>
    </div>
  )
}

RadioBtnGroup.RadioBtn = RadioBtn
