import { SVGProps } from 'react'
import { SVGRProps } from '../Icon/interfaces'

const King = ({
  style,
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <div style={style}>
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26 12H10C9.44772 12 9 12.4477 9 13V29C9 29.5523 9.44772 30 10 30H26C26.5523 30 27 29.5523 27 29V13C27 12.4477 26.5523 12 26 12ZM10 10C8.34315 10 7 11.3431 7 13V29C7 30.6569 8.34315 32 10 32H26C27.6569 32 29 30.6569 29 29V13C29 11.3431 27.6569 10 26 10H10Z"
        fill="#AA3CDE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5581 6.05927C17.3452 5.23936 18.6565 5.23936 19.4436 6.05927L20.713 7.3815L23.4637 6.64469C25.1695 6.1878 26.5871 8.00423 25.7295 9.54787L24.375 11.986L22.6267 11.0147L23.9812 8.57659L21.2304 9.3134C20.5262 9.50204 19.7751 9.29251 19.2702 8.76657L18.0008 7.44434L16.7315 8.76657C16.2266 9.29251 15.4755 9.50204 14.7712 9.3134L12.3042 8.65258L13.8329 10.9457L12.1688 12.0551L10.6401 9.76198C9.61692 8.22725 11.0399 6.24344 12.8216 6.72068L15.2887 7.3815L16.5581 6.05927Z"
        fill="#AA3CDE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5742 23.738C11.719 23.5028 12.0269 23.4295 12.2621 23.5742L12.0001 24.0001C12.2621 23.5742 12.262 23.5742 12.2618 23.5741L12.2617 23.574C12.2618 23.574 12.2618 23.5741 12.2619 23.5741L12.2641 23.5754L12.2753 23.5822C12.2858 23.5885 12.3024 23.5983 12.3248 23.6112C12.3696 23.6372 12.4377 23.6757 12.5273 23.724C12.7065 23.8205 12.9711 23.9557 13.3066 24.1065C13.9787 24.4086 14.9293 24.7705 16.0432 25.0114C18.273 25.4935 21.1108 25.4831 23.706 23.5957C23.9293 23.4333 24.242 23.4827 24.4044 23.706C24.5669 23.9293 24.5175 24.242 24.2942 24.4044C21.3893 26.517 18.2271 26.5067 15.8319 25.9888C14.6334 25.7296 13.6152 25.3416 12.8967 25.0186C12.5368 24.8569 12.2507 24.7109 12.0529 24.6043C11.954 24.551 11.8771 24.5075 11.824 24.4768C11.7975 24.4614 11.7769 24.4493 11.7625 24.4407L11.7455 24.4305L11.7406 24.4275L11.739 24.4265L11.7384 24.4262C11.7382 24.426 11.738 24.4259 12.0001 24.0001L11.738 24.4259C11.5028 24.2812 11.4295 23.9732 11.5742 23.738ZM12.2617 23.574L12.2617 23.574L12.2617 23.574Z"
        fill="#AA3CDE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 6C10.2239 6 10 6.22386 10 6.5C10 6.77614 10.2239 7 10.5 7C10.7761 7 11 6.77614 11 6.5C11 6.22386 10.7761 6 10.5 6ZM10.5 8C11.3284 8 12 7.32843 12 6.5C12 5.67157 11.3284 5 10.5 5C9.67157 5 9 5.67157 9 6.5C9 7.32843 9.67157 8 10.5 8Z"
        fill="#AA3CDE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.5 6C25.2239 6 25 6.22386 25 6.5C25 6.77614 25.2239 7 25.5 7C25.7761 7 26 6.77614 26 6.5C26 6.22386 25.7761 6 25.5 6ZM25.5 8C26.3284 8 27 7.32843 27 6.5C27 5.67157 26.3284 5 25.5 5C24.6716 5 24 5.67157 24 6.5C24 7.32843 24.6716 8 25.5 8Z"
        fill="#AA3CDE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 5C18.5523 5 19 4.55228 19 4C19 3.44772 18.5523 3 18 3C17.4477 3 17 3.44772 17 4C17 4.55228 17.4477 5 18 5ZM18 6C19.1046 6 20 5.10457 20 4C20 2.89543 19.1046 2 18 2C16.8954 2 16 2.89543 16 4C16 5.10457 16.8954 6 18 6Z"
        fill="#AA3CDE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0025 17.9503C12.03 17.6755 12.275 17.475 12.5498 17.5025L17.5498 18.0025C17.8246 18.03 18.025 18.275 17.9976 18.5498C17.9701 18.8246 17.7251 19.025 17.4503 18.9976L12.4503 18.4976C12.1755 18.4701 11.975 18.2251 12.0025 17.9503Z"
        fill="#AA3CDE"
      />
      <path
        d="M23 19C23 19.5523 22.5523 20 22 20C21.4477 20 21 19.5523 21 19C21 18.4477 21.4477 18 22 18C22.5523 18 23 18.4477 23 19Z"
        fill="#AA3CDE"
      />
    </svg>
  </div>
)

export default King
