import { CSSProperties, FC, ReactNode, useState } from 'react'
import classnames from 'classnames'
import InfoIcon from '../Icon/InfoIcon'
import CloseIcon from '../Icon/CloseIcon'

interface AlertProps {
  type?: 'primary' | 'warning' | 'success' | 'error' | 'white'
  className?: string
  style?: CSSProperties
  showIcon?: Boolean
  children?: ReactNode
  noMargin?: Boolean
  closable?: Boolean
  icon?: ReactNode
}

export const Alert: FC<AlertProps> = ({
  type,
  className,
  style,
  showIcon,
  children,
  noMargin = false,
  closable = false,
  icon,
  ...props
}) => {
  const [closed, setClosed] = useState(false)
  const classes = classnames(
    'alert',
    `alert--${type}`,
    noMargin && 'alert--nomargin',
    className
  )

  if (closed) {
    return <></>
  }

  return (
    <div className={classes} style={style} {...props}>
      {showIcon && <div className="alert-icon">{icon || <InfoIcon />}</div>}
      <div className="alert-body">{children}</div>
      {closable && (
        <div className="alert-close" onClick={() => setClosed(true)}>
          <CloseIcon />
        </div>
      )}
    </div>
  )
}

export default Alert
