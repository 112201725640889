import { SVGProps } from 'react'
import { Phone } from 'react-feather'

import { SVGRProps } from './interfaces'

const PhoneIcon = ({
  title,
  titleId,
  style,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <div style={{ display: 'flex', alignItems: 'stretch' }}>
    <Phone style={style} />
  </div>
)

export default PhoneIcon
