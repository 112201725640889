import CancelIcon from '../Icon/CancelIcon'

export const SelectOption = ({
  title,
  onRemove,
}: {
  title: string
  onRemove: () => void
}) => (
  <div className="dropdown-select__option">
    {title}
    <div className="dropdown-select__option-remove" onClick={onRemove}>
      <CancelIcon />
    </div>
  </div>
)
