import { useMemo } from 'react'
import { SelectInputItem, sort } from './helpers'
import { SelectRow, SelectRowMultiple } from './SelectRow'

interface RowGroupProps {
  index: number | string
  option: SelectInputItem
  multiple?: boolean
  handleChangeOption: any
  renderOption: any
  value?: null | string | string[]
  mode?: string
  showSearch?: boolean
  sortingData: boolean
  filterFunc: any
  inputValue: any
  selectedOption: any
  options: SelectInputItem[]
  setDatalistDisplay?: any
}

export const SelectGroupRow = ({
  index,
  option,
  multiple = false,
  handleChangeOption,
  renderOption,
  value,
  setDatalistDisplay,
  mode,
  showSearch,
  sortingData,
  filterFunc,
  inputValue,
  selectedOption,
  options,
}: RowGroupProps) => {
  const filteredSubOptions = useMemo(() => {
    let result = option.options || []

    const tags = mode === 'tags'

    if (tags || showSearch) {
      if (!tags) value = inputValue

      result = result.filter(option =>
        value && typeof value === 'string' ? filterFunc(option, value) : true
      )
    }

    sort(sortingData, result)

    return result
  }, [options, value, selectedOption, mode])

  return (
    <div className="select-group">
      <div className="select-group-title">{option.title}</div>
      <div className="select-group-items">
        {filteredSubOptions.map((child, childIndex) => (
          <>
            {multiple ? (
              <SelectRowMultiple
                key={childIndex}
                option={child}
                index={`${index}-${childIndex}`}
                {...{
                  value,
                  handleChangeOption,
                  setDatalistDisplay,
                  renderOption,
                }}
              />
            ) : (
              <SelectRow
                key={childIndex}
                option={child}
                index={`${index}-${childIndex}`}
                {...{ value, handleChangeOption, renderOption }}
              />
            )}
          </>
        ))}
      </div>
    </div>
  )
}
