import { SVGProps } from 'react'

import { Folder } from 'react-feather'
import { SVGRProps } from './interfaces'

const FolderIcon = ({
  title,
  titleId,
  style,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => {
  return (
    <div style={{ display: 'flex', alignItems: 'stretch' }}>
      <Folder style={style} />
    </div>
  )
}

export default FolderIcon
