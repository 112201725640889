import { useState } from 'react'
import './Image.less'
import { Loader } from '../../Loader/Loader'
import classNames from 'classnames'

interface ImageProps {
  filename: string
  imageClassName?: string
  url: string
}

export const Image: React.FC<ImageProps> = ({
  url,
  filename,
  imageClassName,
}) => {
  const [loading, setLoading] = useState(true)
  const title = decodeURIComponent(filename)
  return (
    <div className="image-viewer">
      <div className={classNames('image-viewer-center ', imageClassName)}>
        {loading && (
          <div className="image-loader-container">
            <Loader isRelative noMinHeight />
          </div>
        )}

        <img
          src={url}
          style={{ display: loading ? 'none' : 'block' }}
          width="auto"
          height="auto"
          alt={title}
          onLoad={() => setLoading(false)}
        />
      </div>
    </div>
  )
}
