import { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { InputField } from '../../../InputField/InputField'
import CloseIcon from '../../../Icon/CloseIcon'
import CheckIcon from '../../../Icon/CheckIcon'
import Edit3Icon from '../../../Icon/EditIcon3'
import classNames from 'classnames'
import DeleteIcon from '../../../Icon/DeleteIcon'
import { PermissionRoleHeaderProps } from '../../PermissionTable.d'
import InnerButton, { ButtonType } from '../../../InnerButton/InnerButton'

export const PermissionRoleHeader = ({
  role,
  headerEditable,
  editable,
  deletable,
  index,
  isEditingTitle,
  lang,
  roles,
  setIsEditingTitle,
  setRoles,
}: PermissionRoleHeaderProps) => {
  const [isEdited, setIsEdited] = useState(false)
  const [valueBackup, setValueBackup] = useState(role.title)
  const [value, setValue] = useState(role.title)
  const onInputChange = (
    evt: ChangeEvent<HTMLInputElement>,
    newVal: string | number
  ) => {
    setValue({ ...value, [lang]: newVal as string })
  }

  const valueLang = useMemo(() => value[lang ?? 'EN'], [value, lang])

  const onEdit = () => {
    setValueBackup(value)
    typeof setIsEditingTitle === 'function' && setIsEditingTitle(true)
    setIsEdited(true)
  }
  const onSave = () => {
    typeof setIsEditingTitle === 'function' && setIsEditingTitle(false)
    setIsEdited(false)
    const result = [...roles]
    result[index].title = value
    setRoles(result)
  }
  const onCancel = () => {
    setValue(valueBackup)
    typeof setIsEditingTitle === 'function' && setIsEditingTitle(false)
    setIsEdited(false)
  }
  const onDelete = () => {
    const result = [...roles]
    roles[index].toDelete = true
    setRoles(result)
  }

  useEffect(() => {
    setValue(role.title)
  }, [role.title])

  const isEditable = useMemo(
    () => (headerEditable || editable) && !isEditingTitle,
    [headerEditable, editable, isEditingTitle]
  )

  return (
    <div className="permission-column">
      {role.icon}
      {!isEdited ? (
        <div
          className={classNames(
            'permission-column-title',
            isEditable && 'permission-column-title--editable',
            deletable && 'permission-column-title--deletable'
          )}
        >
          {valueLang}
          <div className="permission-column-title-actions">
            {isEditable && (
              <InnerButton
                type={ButtonType.link}
                onClick={onEdit}
                icon={<Edit3Icon />}
              />
            )}
            {deletable && (
              <InnerButton
                type={ButtonType.link}
                danger
                onClick={onDelete}
                icon={<DeleteIcon />}
              />
            )}
          </div>
        </div>
      ) : (
        <div className="permission-column-editor">
          <InputField name="name" value={valueLang} onChange={onInputChange} />
          <div className="permission-column-editor-actions">
            <InnerButton
              type={ButtonType.link}
              onClick={onSave}
              icon={<CheckIcon />}
            />
            <InnerButton
              type={ButtonType.link}
              onClick={onCancel}
              icon={<CloseIcon />}
            />
          </div>
        </div>
      )}
    </div>
  )
}
