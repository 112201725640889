import { useMemo } from 'react'
import classNames from 'classnames'
import InnerButton, { ButtonType } from '../InnerButton/InnerButton'
import { PillData } from './FilterPills'

interface FilterPillsAllProps {
  allPillText: string
  pills: Record<string, PillData> // 'pills' est un objet avec des clés de type string et des valeurs de type 'PillData'
  displayCount: boolean
  setCurrentPill: (pillId: string | null) => void // Fonction pour définir le pill actuel, pas d'argument attendu
  currentPill: string | null // Le pill actuel peut être une string ou null si aucun pill n'est sélectionné
}

export const FilterPillsAll = ({
  allPillText,
  pills,
  displayCount,
  setCurrentPill,
  currentPill,
}: FilterPillsAllProps) => {
  const totalCount = useMemo(
    () =>
      Object.keys(pills).reduce(
        (acc, key) => acc + (pills?.[key]?.data?.count || 0),
        0
      ),
    [pills]
  )

  const finalAllPillText = useMemo(() => {
    let text = allPillText
    if (displayCount && totalCount >= 0) {
      text += ` (${totalCount || 0})`
    }
    return text
  }, [allPillText, displayCount, totalCount])

  return (
    <InnerButton
      type={ButtonType.filter}
      onClick={() => setCurrentPill(null)}
      className={classNames(!currentPill && 'selected')}
    >
      {finalAllPillText}
    </InnerButton>
  )
}
