import { SVGProps } from 'react'
import { ArrowRight } from 'react-feather'

import { SVGRProps } from './interfaces'

// TODO: resolve duplicacy with <RightArrowIcon />
const ArrowRightIcon = ({ style }: SVGProps<SVGSVGElement> & SVGRProps) => {
  return <ArrowRight />
}

export default ArrowRightIcon
