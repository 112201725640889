import { Search } from 'react-feather'
import { CSSProperties, SVGProps } from 'react'

interface SVGRProps {
  title?: string
  titleId?: string
  style?: CSSProperties
}

const SearchIcon = ({
  style,
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => {
  return (
    <div>
      <Search style={style} />
    </div>
  )
}

export default SearchIcon
