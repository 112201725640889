import { SVGProps } from 'react'
import { SVGRProps } from './interfaces'

const EnterIcon = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M6.00008 6.66797L2.66675 10.0013L6.00008 13.3346"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.3334 2.66797V7.33464C13.3334 8.04188 13.0525 8.72016 12.5524 9.22025C12.0523 9.72035 11.374 10.0013 10.6667 10.0013H2.66675"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default EnterIcon
