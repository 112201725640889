import { Upload } from 'react-feather'
import { SVGProps } from 'react'
import { SVGRProps } from './interfaces'

const UploadIcon = ({
  title,
  titleId,
  style,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => {
  return <Upload style={style} />
}

export default UploadIcon
