import { SVGProps } from 'react'

interface SVGRProps {
  title?: string
  titleId?: string
}

const Angry = ({
  title,
  titleId,
  style,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <div style={style}>
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" fill="#E5E5E5" />
      <g filter="url(#filter0_d_0_1)">
        <rect x="-56" y="-134" width="551" height="280" rx="2" fill="white" />
      </g>
      <rect
        x="3.5"
        y="3.5"
        width="17"
        height="17"
        rx="2.5"
        stroke="currentColor"
      />
      <path
        d="M15 10H15.01"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.61406 10.3587L10.4505 10.1477C10.7656 10.1238 11.0021 9.84891 10.9788 9.53219C10.9554 9.21593 10.6801 8.98058 10.3668 9.00127L7.5299 9.21226C7.2152 9.23616 6.97826 9.51105 7.00158 9.82776C7.024 10.1298 7.27512 10.3601 7.57152 10.3601C7.5857 10.3601 7.59988 10.3596 7.61406 10.3587Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.585 8.87683C13.5884 8.87286 13.7797 8.65736 14.1532 8.43908C14.4703 8.25374 14.9167 8.06641 15.5103 8.0045C16.0176 7.9533 16.4772 8.07713 16.8245 8.22357C17.2141 8.38788 17.4624 8.57997 17.4709 8.58711C17.6393 8.72285 17.8549 8.64307 17.9522 8.40574C18.0501 8.1692 17.9928 7.86559 17.8241 7.72787C17.7852 7.69573 16.9176 7 15.7555 7C15.6584 7 15.5591 7.00437 15.4581 7.01508C13.9114 7.17621 13.1522 8.09141 13.1206 8.13031C12.9739 8.31049 12.9589 8.62402 13.0873 8.8304C13.2154 9.03638 13.4383 9.05821 13.585 8.87683Z"
        fill="currentColor"
      />
      <line
        x1="8.46887"
        y1="15.8649"
        x2="15.4311"
        y2="15.1388"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <defs>
        <filter
          id="filter0_d_0_1"
          x="-82.1439"
          y="-160.144"
          width="607.389"
          height="336.389"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2.0505" dy="2.0505" />
          <feGaussianBlur stdDeviation="14.0972" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.11 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_0_1"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_0_1"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  </div>
)

export default Angry
