import { FC } from 'react'
import './Flag.less'

interface Properties {
  countryCode?: string
  label?: string
}

export const Flag: FC<Properties> = ({ countryCode = '', label }) => (
  <div className="flag">
    <img
      src={`/flags/${countryCode.toLowerCase()}.png`}
      width="16"
      height="12"
      alt={label || countryCode}
    />
    {label}
  </div>
)
