import React, { FC } from 'react'
import classnames from 'classnames'

export const Button: FC<
  React.ButtonHTMLAttributes<HTMLButtonElement>
> = props => (
  <button {...props} className={classnames('button', props.className)}>
    {props.children}
  </button>
)
