import React, { useMemo } from 'react'
import InnerButton, { ButtonType } from '../InnerButton/InnerButton'
import ChevronLeftIcon from '../Icon/ChevronLeftIcon'
import ChevronRightIcon from '../Icon/ChevronRightIcon'
import classNames from 'classnames'

interface PaginationProps {
  currentPage: number
  totalPages: number
  onPageChange: (page: number) => void
  className?: string
}

export const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  onPageChange,
  className,
}) => {
  const paginationPageCount = totalPages === 2 ? 2 : 3
  //The numbers 2 and 3 indicate the number of pages displayed.
  // If there is only one page, then pagination is not displayed. If there are 3 or more, then strictly 3 is displayed.
  const pageNumbers = useMemo(() => {
    const paginationArray = Array.from(new Array(paginationPageCount))
    if (currentPage < paginationPageCount) {
      return paginationArray.map((v, i) => i + 1)
    }
    if (currentPage === totalPages) {
      return paginationArray
        .map((v, i) => i)
        .reverse()
        .map(i => totalPages - i)
    }

    return paginationArray.map((v, i) => currentPage + i - 1)
  }, [totalPages, currentPage])

  return totalPages > 1 ? (
    <div className={classNames('pagination-container', className && className)}>
      <InnerButton
        className="pagination-button"
        onClick={() => onPageChange(currentPage - 1)}
        type={ButtonType.secondary}
        disabled={currentPage === 1}
        icon={<ChevronLeftIcon />}
      />
      {pageNumbers.map(i => (
        <InnerButton
          type={ButtonType.secondary}
          key={i}
          onClick={() => onPageChange(i)}
          className={classNames(i !== currentPage && 'pagination-button')}
        >
          {`${i}`}
        </InnerButton>
      ))}
      <InnerButton
        className="pagination-button"
        onClick={() => onPageChange(currentPage + 1)}
        type={ButtonType.secondary}
        icon={<ChevronRightIcon />}
        disabled={currentPage === totalPages}
      />
    </div>
  ) : null
}

export default Pagination
