import { SVGProps } from 'react'

interface SVGRProps {
  title?: string
  titleId?: string
  shapeStyle?: any
}

const Clients = ({
  style,
  shapeStyle,
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <div style={{ display: 'flex', alignItems: 'stretch' }}>
    <svg
      width="16"
      height="16"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <circle
        cx="12"
        cy="11"
        r="2.5"
        stroke="currentColor"
        style={shapeStyle}
      />
      <rect
        x="7.5"
        y="3.5"
        width="9"
        height="3"
        rx="0.5"
        stroke="currentColor"
        style={shapeStyle}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 5H5C3.34315 5 2 6.34315 2 8V19C2 20.6569 3.34315 22 5 22H19C20.6569 22 22 20.6569 22 19V8C22 6.34315 20.6569 5 19 5H18V6H19C20.1046 6 21 6.89543 21 8V19C21 20.1046 20.1046 21 19 21H5C3.89543 21 3 20.1046 3 19V8C3 6.89543 3.89543 6 5 6H6V5Z"
        fill="currentColor"
        stroke="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 17H15C16.1046 17 17 17.8954 17 19H18C18 17.3431 16.6569 16 15 16H9C7.34315 16 6 17.3431 6 19H7C7 17.8954 7.89543 17 9 17Z"
        fill="currentColor"
        stroke="currentColor"
      />
      <line x1="6.5" y1="19" x2="6.5" y2="20" stroke="currentColor" />
      <line x1="17.5" y1="19" x2="17.5" y2="20" stroke="currentColor" />
    </svg>
  </div>
)

export default Clients
