import { Calendar } from 'react-feather'
import { SVGProps } from 'react'

interface SVGRProps {
  title?: string
  titleId?: string
}

const CalendarIcon = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => {
  return (
    <div>
      <Calendar />
    </div>
  )
}

export default CalendarIcon
