import classNames from 'classnames'
import React, { FC, useRef, ReactNode, useState, CSSProperties } from 'react'
import UploadCloudIcon from '../Icon/UploadCloudIcon'
import CloseIcon from '../Icon/CloseIcon'
import { Tooltip } from '../Tooltip/Tooltip'

interface Props {
  accept?: string
  disabled?: boolean
  hideCloudIcon?: boolean
  fileName?: string
  description: string
  labels?: string
  tiny?: boolean
  preview?: ReactNode
  styleDropZone?: CSSProperties
  styleDropZoneInput?: CSSProperties
  styleDropZoneCloud?: CSSProperties
  deleteUploadedFile?: () => void
  onChange: (files: File[]) => void
}

export const DropZone: FC<Props> = ({
  disabled,
  fileName,
  labels,
  preview,
  description,
  accept = 'image/*,.png,.jpg,.gif,.jpeg',
  onChange,
  styleDropZone,
  styleDropZoneInput,
  tiny = false,
  hideCloudIcon = false,
}) => {
  const [hightlight, setHightlight] = useState(false)
  const fileInputRef = useRef<HTMLInputElement>(null)

  const openFileDialog = () => {
    if (disabled) return
    fileInputRef?.current?.click()
  }

  const fileListToArray = (list: FileList): File[] => {
    const array = []
    for (let i = 0; i < list.length; i++) {
      array.push(list.item(i)!)
    }
    return array
  }

  const onDeleteUploadedFile = () => {
    fileInputRef!.current!.value = ''
    onChange([])
  }

  const addFiles = (evt: React.ChangeEvent<HTMLInputElement>) => {
    if (disabled) return
    const files = evt.target.files
    if (files) {
      const array = fileListToArray(files)
      onChange(array)
    }
    fileInputRef!.current!.value = ''
  }

  const onDragOver = (evt: React.DragEvent) => {
    evt.preventDefault()

    if (disabled) return

    setHightlight(true)
  }

  const onDragLeave = () => {
    setHightlight(false)
  }

  const onDrop = (event: React.DragEvent) => {
    event.preventDefault()

    if (disabled) return

    const files = event.dataTransfer?.files
    if (files) {
      const array = fileListToArray(files)
      onChange(array)
    }
    setHightlight(false)
  }

  return (
    <div
      style={styleDropZone}
      className={classNames(
        'Dropzone',
        hightlight && 'hightlight',
        tiny && 'tiny'
      )}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
    >
      <input
        title="file"
        style={styleDropZoneInput}
        disabled={disabled}
        ref={fileInputRef}
        accept={accept}
        className="FileInput"
        type="file"
        multiple
        onChange={addFiles}
      />

      {fileName ? (
        <div className="drop-zone--picked">
          {preview && (
            <div className="drop-zone--picked-preview">{preview}</div>
          )}
          <div className="drop-zone--picked-text">
            <Tooltip title={fileName}>
              <div className="drop-zone-filename">{fileName}</div>
            </Tooltip>
            <div onClick={onDeleteUploadedFile} className="drop-zone-cross">
              <CloseIcon />
            </div>
          </div>
        </div>
      ) : (
        <div className="drop-zone--upload" onClick={openFileDialog}>
          {!hideCloudIcon && (
            <div className="drop-zone-upload-icon">
              <UploadCloudIcon />
            </div>
          )}
          <div>{labels}</div>
        </div>
      )}
      {description && (
        <div className="drop-zone-description">{description}</div>
      )}
    </div>
  )
}
