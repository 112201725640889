import { SVGProps } from 'react'
import { SVGRProps } from '../Icon/interfaces'

const Teenage = ({
  style,
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <div>
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24 20C24 20.5523 23.5523 21 23 21C22.4477 21 22 20.5523 22 20C22 19.4477 22.4477 19 23 19C23.5523 19 24 19.4477 24 20Z"
        fill="#435AD8"
      />
      <path
        d="M14 20C14 20.5523 13.5523 21 13 21C12.4477 21 12 20.5523 12 20C12 19.4477 12.4477 19 13 19C13.5523 19 14 19.4477 14 20Z"
        fill="#435AD8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.04297 17V29C6.04297 30.6568 7.38612 32 9.04297 32H26.956C28.6129 32 29.956 30.6568 29.956 29V17H27.956V29C27.956 29.5522 27.5083 30 26.956 30H9.04297C8.49068 30 8.04297 29.5522 8.04297 29V17H6.04297Z"
        fill="#435AD8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29 14H7L7 16H29V14ZM7 12C5.89543 12 5 12.8954 5 14V16C5 17.1046 5.89543 18 7 18H29C30.1046 18 31 17.1046 31 16V14C31 12.8954 30.1046 12 29 12H7Z"
        fill="#435AD8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 9H28V12H8L8 9ZM6 9C6 7.89543 6.89543 7 8 7H28C29.1046 7 30 7.89543 30 9V12C30 12.3643 29.9026 12.7058 29.7324 13H6.26756C6.09739 12.7058 6 12.3643 6 12V9Z"
        fill="#435AD8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 9H25V12H27V9C27 7.89543 26.1046 7 25 7H11C9.89543 7 9 7.89543 9 9V12H11L11 9Z"
        fill="#435AD8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 9H20V12H22V9C22 7.89543 21.1046 7 20 7H16C14.8954 7 14 7.89543 14 9V12H16V9Z"
        fill="#435AD8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 7C16 5.89543 16.8954 5 18 5C19.1046 5 20 5.89543 20 7V7.2C20 7.48444 19.9406 7.75501 19.8336 8H16.1664C16.0594 7.75502 16 7.48444 16 7.2V7Z"
        fill="#435AD8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 17V13H10V17H8Z"
        fill="#435AD8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 17V13H13V17H11Z"
        fill="#435AD8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 17V13H17V17H15Z"
        fill="#435AD8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 17V13H21V17H19Z"
        fill="#435AD8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23 17V13H25V17H23Z"
        fill="#435AD8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26 17V13H28V17H26Z"
        fill="#435AD8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5742 24.738C11.719 24.5028 12.0269 24.4295 12.2621 24.5742L12.0001 25.0001C12.2621 24.5742 12.262 24.5742 12.2618 24.5741L12.2617 24.574C12.2618 24.574 12.2618 24.5741 12.2619 24.5741L12.2641 24.5754L12.2753 24.5822C12.2858 24.5885 12.3024 24.5983 12.3248 24.6112C12.3696 24.6372 12.4377 24.6757 12.5273 24.724C12.7065 24.8205 12.9711 24.9557 13.3066 25.1065C13.9787 25.4086 14.9293 25.7705 16.0432 26.0114C18.273 26.4935 21.1108 26.4831 23.706 24.5957C23.9293 24.4333 24.242 24.4827 24.4044 24.706C24.5669 24.9293 24.5175 25.242 24.2942 25.4044C21.3893 27.517 18.2271 27.5067 15.8319 26.9888C14.6334 26.7296 13.6152 26.3416 12.8967 26.0186C12.5368 25.8569 12.2507 25.7109 12.0529 25.6043C11.954 25.551 11.8771 25.5075 11.824 25.4768C11.7975 25.4614 11.7769 25.4493 11.7625 25.4407L11.7455 25.4305L11.7406 25.4275L11.739 25.4265L11.7384 25.4262C11.7382 25.426 11.738 25.4259 12.0001 25.0001L11.738 25.4259C11.5028 25.2812 11.4295 24.9732 11.5742 24.738ZM12.2617 24.574L12.2617 24.574L12.2617 24.574Z"
        fill="#435AD8"
      />
    </svg>
  </div>
)

export default Teenage
