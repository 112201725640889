import { forwardRef } from 'react'
import { PdfContent } from './PdfContent'
import './Pdf.less'

// Define the types for the props
interface PdfProps {
  url: string
  page?: number
  scale: number
  containerHeight: number | null
  onDocumentComplete: (data: any) => void
  pageWidth: number | null
  pageHeight: number | null
  uploading?: boolean
}

export const Pdf = forwardRef<HTMLDivElement, PdfProps>(
  (
    {
      url,
      page,
      scale,
      containerHeight,
      onDocumentComplete,
      pageWidth,
      pageHeight,
      uploading,
    },
    ref
  ) => (
    <div
      className="container viewer pdf-viewer-main"
      style={
        uploading
          ? { height: containerHeight + 'px' }
          : { maxHeight: containerHeight + 'px', maxWidth: '80%' }
      }
      ref={ref}
    >
      <PdfContent
        file={url}
        onLoadSuccess={onDocumentComplete}
        width={pageWidth}
        height={pageHeight}
        pageNumber={page}
        scale={scale}
      />
    </div>
  )
)
