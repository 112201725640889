import { Minimize2 } from 'react-feather'
import { SVGProps } from 'react'
import { SVGRProps } from './interfaces'

const MinimizeIcon = ({
  title,
  titleId,
  style,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => {
  return (
    <div style={{ display: 'flex', alignItems: 'stretch' }}>
      <Minimize2 style={style} />
    </div>
  )
}

export default MinimizeIcon
