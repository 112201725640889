import React, { SyntheticEvent } from 'react'

import { RadioContext } from './Context'

const isChecked = (a: React.ReactText, b?: React.ReactText) => a === b

export interface RadioButtonProps
  extends Omit<React.HTMLAttributes<HTMLInputElement>, 'onClick'> {
  disabled?: boolean
  label: React.ReactNode
  onBlur?: (evt: React.FocusEvent<HTMLInputElement>) => void
  onChange?: (evt: SyntheticEvent) => void
  onClick?: (
    evt: React.MouseEvent<HTMLInputElement>,
    val?: React.ReactText
  ) => void
  onFocus?: (evt: React.FocusEvent<HTMLInputElement>) => void
  value: React.ReactText
}

export const Button = React.forwardRef<HTMLInputElement, RadioButtonProps>(
  (
    { className, disabled: disabledButton, value, label, ...props },
    forwardedRef
  ) => {
    const {
      checkedValue,
      onChange,
      disabled: disabledGroup,
      error,
      name,
    } = React.useContext(RadioContext)
    const ref = React.useRef<HTMLInputElement>(
      null as unknown as HTMLInputElement
    )
    React.useImperativeHandle(forwardedRef, () => ref.current)
    const disabled = disabledButton || disabledGroup

    const [isFocused, setFocus] = React.useState(false)

    const handleFocus = (evt: React.FocusEvent<HTMLInputElement>) => {
      if (disabled) return
    }

    const handleBlur = (evt: React.FocusEvent<HTMLInputElement>) => {
      if (disabled) return
    }

    const handleClick = (evt: React.MouseEvent<HTMLInputElement>) => {
      ref.current.focus()
    }

    const checked = isChecked(value, checkedValue)
    return (
      <label className="psds-radio-button">
        <input
          {...props}
          checked={checked}
          onChange={props.onChange}
          onClick={disabled ? undefined : handleClick}
          onFocus={handleFocus}
          onBlur={handleBlur}
          type="radio"
          name={name}
          ref={ref}
          value={value}
        />

        <div className="psds-radio-button__label">{label}</div>
      </label>
    )
  }
)

Button.displayName = 'Radio.Button'

export default Button
